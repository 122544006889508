.dashed-line {
  margin: 1rem 0 0;
  border-top: 1px dashed #c7c7c7;
  height: 0; }

.card {
  border-bottom: 1px dashed $border;
  padding: 1.7rem 0;
  color: $info;
  font-size: 1.6rem;
  figure {
    padding-top: 2.5rem;
    text-align: center;
    img {
      max-width: 100%;
      height: auto; } }
  figcaption {
    padding: 1.5rem 0; }
  .title {
    &:first-child {
      margin-top: 0; } }
  &_no-border {
    border: 0; } }

.icons-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }

.icon-wrap {
  position: relative;
  //&:first-child
  //  .tip
  //    left: -.5rem
  //    transform: none
  //    &:before
  //      margin-left: 0
  //      left: 1rem
  //&:last-child
  //  .tip
  //    left: auto
  //    right: -.5rem
  //    transform: none
  //    &:before
  //      margin-left: 0
  //      left: auto
  //      right: 1rem
  &.is-disabled {
    .icon {
      fill: #bababa; } }
  .icon {
    fill: $main;
    font-size: 1.9rem;
    &-wifi {
      font-size: 1.6rem; }
    &-air {
      font-size: 1.3rem; }
    &-tv {
      font-size: 1.8rem; }
    &-microwave {
      font-size: 1.5rem; } } }

.tooltipster-base {
  z-index: 2 !important;
  padding: .5rem .8rem;
  height: auto !important;
  color: $info;
  font-size: 1.3rem;
  line-height: 1.2;
  font-weight: 300;
  white-space: nowrap;
  filter: drop-shadow(0 0 3px rgba(127, 127, 127, 0.65));
  background: #fff;
  &:before {
    content: '';
    position: absolute;
    top: -4px;
    left: 50%;
    margin-left: -3px;
    @include arr(6,5,#fff,t); } }


.table-row-wrap {
  .pseudolink {
    margin-bottom: 3rem; } }

.table-row {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 -.5rem 1.6rem;
  font-size: 1.5rem;
  &:after {
    content: '';
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 0;
    border: 1px dotted $info; }
  &__cell {
    position: relative;
    padding: 0 .5rem;
    z-index: 2;
    background: #fff;
    a {
      font-weight: 700;
      text-decoration: underline; } }
  &.is-hidden {
    display: none;
    &.is-active {
      display: flex; } } }
