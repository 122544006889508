.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 52;
  display: flex;
  align-items: stretch;
  padding: 0 1rem;
  background-image: linear-gradient(to top, #faee38 0%, #faee38 1%, #fff891 100%);
  &__right,
  &__left {
    display: flex;
    width: 50%;
    align-items: center; }
  &__right {
    justify-content: flex-end; }
  .drop {
    margin-left: 1rem; } }

.burger {
  margin-left: -1rem;
  border-right: 1px solid #fff;
  width: 6.2rem;
  height: 4.9rem;
  line-height: 1;
  font-size: 0;
  background: $main;
  &.is-active {
    i {
      background: none;
      &:before {
        transform: translateY(1rem) rotate(45deg); }
      &:after {
        transform: translateY(-1rem) rotate(-45deg); } } }

  i {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    height: 3px;
    width: 3.6rem;
    background: #fff;
    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      height: 3px;
      background: #fff;
      width: 3.6rem;
      transition: transform .3s; }
    &:before {
      top: -1rem; }
    &:after {
      bottom: -1rem; } }
  &_main {
    position: absolute;
    right: 0;
    top: 4.9rem;
    height: 4.1rem;
    width: 4.9rem;
    background: none;
    i {
      background: $main;
      width: 2.4rem;
      &:before,
      &:after {
        background: $main;
        width: 2.4rem; }
      &:before {
        top: -.7rem; }
      &:after {
        bottom: -.7rem; } }
    &.is-active {
      i {
        background: none;
        &:before {
          transform: translateY(.7rem) rotate(45deg); }
        &:after {
          transform: translateY(-.7rem) rotate(-45deg); } } } } }

.drop {
  position: relative;
  font-size: 1.4rem;
  font-weight: 300;
  color: #787100;
  &_currency {
    font-size: 1.1rem;
    text-transform: uppercase; }
  img {
    vertical-align: middle;
    margin-right: .7rem; }
  &__lang {
    font-size: 1.4rem; }
  &__value {
    display: block;
    position: relative;
    z-index: 2;
    border: 1px solid #d5cc3f;
    padding: 0 2rem 0 .8rem;
    height: 3.4rem;
    line-height: 3.2rem;
    @include round;
    background: $second;
    @include nosel;
    @include text-overflow;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -3px;
      right: .7rem;
      @include arr(7, 5, #d5cc3f, b); }
    &.is-active {
      border-bottom-color: transparent;
      border-radius: 3px 3px 0 0; } }
  &__list {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: -.8rem;
    border: 1px solid #d5cc3f;
    border-top: 0;
    padding: 4px 0;
    transform: translateY(-1rem);
    min-width: 100%;
    border-radius: 0 0 3px 3px;
    background: $second;
    transition: opacity, transform, .3s;
    &.is-active {
      opacity: 1;
      visibility: visible;
      transform: none; }
    a {
      display: block;
      padding: .5rem 2rem .5rem .8rem;
      color: #787100;
      text-decoration: none; } } }
