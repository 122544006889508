.popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: 10;
  visibility: hidden;
  transition: opacity .3s;
  &__in {
    position: relative;
    z-index: 2;
    padding: 2rem;
    width: 26rem;
    color: $info;
    font-size: 1.3rem;
    line-height: 1;
    font-weight: 300;
    box-shadow: 0 0 3px rgba(127, 127, 127, 0.65);
    background: #fff;
    text-align: left;
    li {
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0; } }
    .title {
      margin: 1rem 0 2rem; } }
  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(#000, .3); }
  &.is-active {
    opacity: 1;
    visibility: visible; }
  &_fluid {
    .popup__in {
      width: 90%; } } }
