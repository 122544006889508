.ui-widget-content {
  position: relative;
  z-index: 8 !important;
  padding-bottom: .5rem;
  color: $info; }

.ui-datepicker {
  width: 300px;
  margin-top: 11px;
  display: none;
  border: 1px solid $border;
  background: #fff; }

.ui-datepicker-inline {
  display: block;
  margin: 0 auto;
  box-shadow: none;
  border-radius: 0;
  tbody {
    td {
      border-color: #dedede; } } }

.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: 10px 0; }

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 50%;
  margin-top: -2.5rem;
  width: 4rem;
  height: 5rem;
  @include hide-text;
  cursor: pointer; }

.ui-datepicker .ui-datepicker-prev {
  left: 0;
  background: url(../img/caret-main-prev.svg) 50% 50% no-repeat; }

.ui-datepicker .ui-datepicker-next {
  right: 0;
  background: url(../img/caret-main-next.svg) 50% 50% no-repeat; }

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: 50%;
  margin-top: -8px; }

.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center; }

.ui-datepicker-month,
.ui-datepicker-year {
  width: 45%;
  font-size: 1.8rem;
  margin: 0; }

.ui-datepicker-month {
  margin-right: 5%; }

.ui-datepicker table {
  width: 26rem;
  border-collapse: collapse;
  margin: 0 auto;
  table-layout: fixed; }

.ui-datepicker th {
  padding: .6rem .5rem;
  text-align: center;
  font-size: 1.5rem;
  color: $info;
  font-weight: 300;
  border: 0; }

.ui-datepicker td {
  vertical-align: middle;
  border-top: 1px solid #dedede;
  &:first-child {
    border-left: 0; }
  &.ui-datepicker-unselectable {
    a, span {
      color: #e5e5e5; }
    &.ui-datepicker-other-month {
      a, span {
        text-decoration: line-through;
        color: lighten($info, 30%); } } }
  //+ .ui-datepicker-range
  //  a, span
  //    background: $main
  //    color: #fff
  &.ui-datepicker-today {
    a, span {
      font-weight: 700;
      color: #000; } }
  &.ui-datepicker-current-day {
    a, span {
      background: $main;
      color: #fff; }
    &.ui-datepicker-today {
      a, span {
        background: none;
        font-weight: 700;
        color: #000; } } }
  &.ui-datepicker-range {
    a, span {
      background: #a6ffcc;
      color: #2ecc71; } }
  &.ui-datepicker-busy {
    a, span {
      background: #fe5555;
      color: #fff; } }
  &.ui-datepicker-range-start,
  &.ui-datepicker-range-end {
    a, span {
      background: $main !important;
      color: #fff !important; }
    //&.ui-datepicker-current-day
    //  a, span
    //    background: $main
 } }    //    color: #fff

.ui-datepicker td span,
.ui-datepicker td a {
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: 1.8rem;
  color: $info;
  width: 100%;
  height: 40px;
  line-height: 40px; }

.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: .7em 0 0 0;
  padding: 0 .2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0; }

.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: .5em .2em .4em;
  cursor: pointer;
  padding: .2em .6em .3em .6em;
  width: auto;
  overflow: visible; }

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left; }

.ui-datepicker.ui-datepicker-multi {
  width: auto; }

.ui-datepicker-multi .ui-datepicker-group {
  float: left; }

.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto .4em; }

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%; }

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%; }

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%; }

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0; }

.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left; }

.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0; }
