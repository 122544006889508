$main: #2ecc71;
$second: #fff88d;
$meta: #adab8f;
$text: #454343;
$info: #565656;
$border: #e1e1e1;
$danger: #e7371b;
$panel: #f2f2f2;

@mixin round {
  border-radius: 3px; }

@mixin font {
  font-family: 'Roboto', -apple-system-body, sans-serif; }
