.filter {
  height: 7.2rem;
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background: $panel;
    height: 7.2rem;
    font-size: 1.6rem; }
  &__date {
    font-size: 1.6rem;
    &-selected {
      display: none; }
    &.is-active {
      .filter__date-default {
        display: none; }
      .filter__date-selected {
        display: block; } } }
  &__btn {
    position: relative;
    background: none;
    .icon {
      fill: $main;
      font-size: 2.5rem; }
    .window__arr {
      display: none; }
    &.is-active {
      .window__arr {
        display: block;
        right: 50%;
        transform: translate(50%,0);
        bottom: -21px;
        top: auto; } } }
  &__counter {
    position: absolute;
    bottom: .3rem;
    right: 1.4rem;
    padding: .1rem .4rem;
    min-width: 1.6rem;
    min-height: 1.6rem;
    text-align: center;
    line-height: 1;
    font-size: 1.4rem;
    font-weight: 300;
    color: #fff;
    background: #dc1f26;
    border-radius: 50%;
    transition: opacity .3s;
    &:empty {
      opacity: 0; } } }

.window {
  position: fixed;
  top: 12rem;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  transform: translate3d(-100%,0,0);
  transition: transform .3s;
  &.is-active {
    transform: translate3d(0,0,0);
    .window__overlay {
      background: rgba(#000, .3);
      transition: background .2s .3s; } }
  &_dates {
    z-index: 50;
    .window__in {
      min-height: 45rem; }
    .window__arr {
      right: auto;
      left: 1.7rem; } }
  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  &__in {
    position: absolute;
    top: 0;
    right: 0;
    max-height: 100%;
    left: 0;
    z-index: 20;
    padding: 1rem;
    background: #fff;
    .field-row {
      margin-top: 20px; } }
  .checkbox {
    margin-bottom: 1.1rem; }
  &__arr {
    position: absolute;
    right: 1.7rem;
    top: -1.3rem;
    @include arr(12,14,#fff,t); }
  .burger {
    top: 0;
    z-index: 25;
    &.click {} }

  .form-action {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background: #fff;
    margin: 0; } }

.message {
  margin: 1.5rem 0 2rem;
  border: 4px solid;
  padding: 2rem 2rem;
  background: #fffcd4;
  font-size: 1.6rem;
  font-weight: 300;
  color: #3b3b3b;
  text-align: center;
  &_error {
    border-color: #ff4b4b;
    .message__main {
      margin-top: 1.7rem;
      color: #df1820; } }
  a {
    color: #3dcf7b; } }

.fix-filter-top {
  top: 49px;
  transition: all 0.5s; }

@include rmin(600) {
  .window {
    .form-action {
      position: absolute; } }
  .window__in {
    padding-bottom: 61px; } }

.sort {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translate3d(0, 100%, 0);
  transition: transform .5s;
  transition: {}
  background: #f2f2f2;
  margin: 0;
  z-index: 10;
  &-show {
    transform: translate3d(0, 0, 0); }
  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px dashed #2ecc71; }
  &__cell {
    position: relative;
    width: 50%;
    padding: 1.5rem .8rem;
    &:first-of-type {
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 35px;
        background: #e0e0e0;
        right: 0; } }
    &-active {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 48%;
        border-width: 16px 13px 0 13px;
        border-style: solid;
        border-color: #fff #eef3f9; } } }
  &__item {
    position: absolute;
    bottom: 72px;
    width: 100%;
    background: #fff; }


  &__checkbox {
    vertical-align: top;
    width: 17px;
    height: 17px;
    margin: 0 3px 0 0; }

  &__checkbox + label {
    cursor: pointer;
    display: block;
    font-size: 18px;
    color: #565656; }

  &__checkbox:not(checked) {
    position: absolute;
    opacity: 0; }

  &__checkbox:not(checked) + label {
    position: relative;
    padding: 16px 0 16px 50px; }

  &__checkbox:not(checked) + label:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 10px;
    width: 25px;
    height: 25px; }

  &__checkbox:not(checked) + label:after {
    content: '';
    position: absolute;
    background-image: url("../img/sort-select.png");
    top: 17px;
    left: 10px;
    width: 25px;
    height: 18px;
    opacity: 0;
    transition: all .2s; }

  &__checkbox:checked + label:after {
    opacity: 1; } }

.btn_sort {
  color: #2ecc71;
  background: inherit;
  font-weight: normal;
  &:active {
    background: inherit; } }

.assort-item {
  transform: translate3d(-100%, 0, 0);
  transition: transform .5s;
  border-top: 1px dashed #2ecc71;
  &-show {
    transform: translate3d(0, 0, 0); } }

.window-close {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 10px;
  right: 10px;
  cursor: pointer;
  &:before, &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 3px;
    background: #2ecc71;
    width: 2.6rem; }

  &:before {
    transform: rotate(45deg); }
  &:after {
    transform: rotate(-45deg); } }



