html {
  font-size: 62.5%; }

body {
  color: $text;
  @include font;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.33;
  min-width: 320px; }

a {
  text-decoration: none;
  color: $main; }

.hidden {
  display: none; }

.inner {
  padding: 4.9rem 1rem 0;
  > .filter {
    margin: 0 -1rem; } }

.page-head {
  .title {
    margin: 1.7rem auto; } }

.text-center {
  text-align: center !important; }

.text-right {
  text-align: right !important; }

.text-left {
  text-align: left !important; }

.title {
  max-width: 92%;
  margin: 2.2rem auto 1.9rem;
  text-align: center;
  color: $info;
  font-size: 1.8rem;
  font-weight: 700;
  &_small {
    margin: 2.2rem 0 1.9rem;
    font-size: 1.6rem; }
  &_tiny {
    font-size: 1.5rem; }
  &_left {
    max-width: inherit;
    text-align: left; }
  &_line {
    position: relative;
    padding-bottom: 2.5rem;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -7rem;
      width: 14.1rem;
      height: 1px;
      background: $border; } }
  &_light {
    margin-bottom: 1rem;
    font-weight: 300; }
  &_main {
    color: $main; }
  &_width {
    max-width: inherit; }

  &_mb {
    margin-bottom: 0; } }

.form-wrap {
  margin-bottom: 1.5rem; }

.form {
  padding: 1.5rem 1rem;
  margin: 0 -1rem;
  background: $panel;
  &__group {
    margin-bottom: 2.5rem; }
  &__dots {
    margin-bottom: 1.4rem;
    border: 1px dashed #c3c3c3;
    padding: 0 1.7rem; }
  .title {
    max-width: inherit;
    margin: 0 0 1.5rem; }
  &_contact {
    position: relative; } }

.field-row {
  position: relative;
  display: flex;
  margin: 0 -1rem;
  align-items: flex-end;
  justify-content: space-between;
  .field {
    padding: 0 1rem;
    flex: auto;
    &:first-child {
      .field__datepicker {
        left: 1rem;
        &:before {
          left: 40px; }
        &:after {
          left: 39px; } } }
    &:last-child {
      .field__datepicker {
        right: 1rem;
        &:before {
          right: 40px; }
        &:after {
          right: 39px; } } } } }


.field {
  display: block;
  width: 100%;
  position: relative;
  margin-bottom: 1.8rem;
  &.is-active {
    .field__datepicker {
      opacity: 1;
      visibility: visible; } }
  .input_datepicker {
    font-size: 1.5rem;
    &[disabled] {
      background: #fff;
      -webkit-text-fill-color: #85857e !important;
      opacity: 1 !important; } }
  &__in {
    position: relative;
    &.is-active {
      .input {
        box-shadow: inset 0 0 0 1px $main; } } }
  &__datepicker {
    position: absolute;
    top: 100%;
    z-index: 50;
    margin: 1rem 0 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s;
    &:before,
    &:after {
      content: '';
      position: absolute; }
    &:before {
      z-index: 10;
      top: -7px;
      @include arr(14,8, #fff, t); }
    &:after {
      z-index: 9;
      top: -8px;
      @include arr(16,9, $border, t); }
    &.is-active {
      opacity: 1;
      visibility: visible; } }
  &__title {
    display: block;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    font-weight: 300;
    color: $info; }
  .icon {
    position: absolute;
    z-index: 2;
    pointer-events: none;
    left: 1.4rem;
    top: 50%;
    transform: translateY(-50%);
    fill: #bab89f;
    &-email {
      font-size: 1.2rem; } }
  span {
    &.is-invalid {
      position: absolute;
      left: 0;
      top: -1.4rem;
      color: $danger;
      font-size: 1rem; } }

  &_no-icon {
    .input {
      padding: 0 1.3rem;
      &_textarea {
        padding: 1.3rem; } } } }

.input {
  display: block;
  padding: 0 1rem 0 4rem;
  width: 100%;
  height: 4.1rem;
  box-shadow: inset 0 0 0.4rem 0.1rem rgba(58, 57, 34, 0.25);
  @include round;
  @include font;
  font-weight: 300;
  color: #85857e;
  background: #fff;
  -webkit-appearance: none;
  font-size: 1.6rem;
  text-align: left;
  @include placeholder {
    color: #85857e;
    font-size: 1.5rem; }
  &_textarea {
    border: 0;
    resize: none;
    height: 13rem;
    padding: 1.3rem; }
  &_promo {
    padding: 0 1.2rem;
    height: 2.8rem;
    font-size: 1.3rem;
    background-color: #fffcd2;
    @include placeholder {
      font-size: 1.3rem; } }
  &:focus {
    box-shadow: inset 0 0 0 1px $border;
    ~ .icon {
      fill: $main; } }
  &.is-invalid {
    border: 1px solid $danger; } }
span.input {
  line-height: 4.1rem; }

.select {
  display: block;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    right: 1.8rem;
    top: 50%;
    margin-top: -3px;
    @include arr(8,7,$info,b); }
  select {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-appearance: none; }
  .input {
    padding-right: 4rem;
    height: 4.1rem;
    line-height: 4.1rem;
    &.is-empty {
      color: #85857e;
      font-size: 1.5rem; } } }

.btn-wrap {
  margin: 2.5rem 0; }

.btn-group {
  margin: 3rem -1rem;
  border-bottom: 1px solid $border;
  padding: 0 1rem 2.2rem;
  .btn {
    margin-bottom: 1.2rem; } }

.btn {
  display: block;
  position: relative;
  padding: 0 .5rem;
  width: 100%;
  height: 4.1rem;
  line-height: 4.1rem;
  @include round;
  background: $main;
  color: #fff;
  @include font;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  @include text-overflow;
  &:active {
    background: darken($main, 5%); }
  &__icon {
    position: absolute;
    left: .9rem;
    top: 50%;
    line-height: 1;
    transform: translateY(-50%);
    .icon-print {
      font-size: 2.3rem; }
    .icon-download {
      font-size: 1.8rem; } }
  &_normal {
    text-transform: none; }
  &_light {
    font-size: 1.5rem;
    font-weight: 300; }
  &_plain {
    border: 1px solid $main;
    background: none;
    color: $main; }
  &_mb {
    margin-bottom: 1rem; }
  &_tiny {
    font-size: 1.2rem;
    text-transform: none;
    font-weight: 300;
    height: 2.8rem;
    line-height: 2.6rem; } }

.logo {
  padding: .8rem 0 0;
  @include hide-text;
  text-align: center; }

.logo-text {
  max-width: 27rem;
  margin: 0 auto;
  padding: .7rem 0;
  color: #3b3b3b;
  font-size: 1.2rem;
  font-weight: 300;
  text-align: center; }

.btn-more-wrap {
  padding: 0 2.5rem;
  &_mb {
    margin-bottom: 3.5rem; }
  &_mt {
    margin-top: 2rem; } }


.btn-more {
  display: block;
  padding: 0 .5rem;
  width: 100%;
  height: 4.2rem;
  line-height: 4rem;
  @include round;
  border: 1px dashed $main;
  color: $main;
  @include font;
  font-size: 1.5rem;
  font-weight: 300;
  text-transform: uppercase;
  background: none;
  text-align: center;
  @include text-overflow;
  &_small {
    height: 3.2rem;
    line-height: 3rem;
    font-size: 1.4rem; }
  &_no-border {
    border: 0; } }

.section {
  margin: 3.2rem -1rem 0;
  padding: .5rem 1rem 3.5rem;
  border-top: 1px solid $border;
  border-bottom: 1px solid $border; }

.pseudolink {
  display: inline-block;
  border-bottom: 1px dashed;
  text-decoration: none;
  color: $main;
  background: none;
  @include font;
  font-size: 1.6rem;
  &_small {
    font-size: 1.4rem; }
  &_big {
    font-size: 1.8rem; } }

.readmore {
  margin-bottom: 3.3rem;
  &_single {
    margin-bottom: 0; }
  &.is-active {
    .readmore__short {
      display: none; }
    .readmore__full {
      display: block; } }
  &__short {
    margin-bottom: 1rem;
    height: 6rem; }
  &__full {
    margin-bottom: 1rem;
    display: none; } }

.rate-group {
  margin-bottom: .7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #44d07d;
  font-size: 1.3rem;
  font-weight: 300;
  &__left,
  &__right {
    display: flex;
    justify-content: center; }
  &__left {
    width: 40%; }
  &__right {
    width: 60%; } }

.rate-wrap {
  text-align: center;
  .rate {
    margin-bottom: .5rem; } }

.hidden-map {
  text-align: center;
  .map {
    margin: 1.5rem 0;
    &__iframe {
      padding: 0;
      opacity: 0;
      transition: padding .3s, opacity .3s .3s; }
    &.is-active {
      .map__iframe {
        padding: 40% 0;
        opacity: 1; } } } }

.map-wrap {
  margin: 0 -1rem;
  padding: 0 1rem;
  border-top: 1px solid $border; }

.map {
  position: relative;
  margin: 0 -1rem;
  &__body {
    padding: 63% 0; }
  &__iframe {
    padding: 40% 0;
    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; } }
  &_marked {
    padding: 1rem 1rem 1.5rem;
    background: $second;
    .title {
      margin-bottom: 3.1rem; } }
  &_small {
    .map__body {
      padding: 27% 0; } } }

.img {
  margin-bottom: 2rem;
  figcaption {
    padding: 2.2rem .7rem;
    font-size: 1.8rem;
    color: $text;
    line-height: 1.6;
    strong {
      display: block; } }
  img {
    display: block;
    width: 100%; } }

.collapse {
  margin-bottom: 3.3rem;
  &__head {
    margin: 0 -1rem 1px;
    padding: 1.5rem 2.1rem;
    color: #fff;
    line-height: 1.1;
    font-size: 1.8rem;
    font-weight: 700;
    background: $main; }
  &__body {
    padding: 2.2rem 0;
    display: none;
    .article {
      p {
        &:first-child {
          margin-top: 0; }
        &:last-child {
          margin-bottom: 0; } } } }
  &_inner {
    .collapse__head {
      margin: 1px 0 0;
      border: 1px solid $main;
      padding: 1.5rem;
      background: none;
      color: $main;
      font-size: 1.5rem;
      font-weight: 300;
      &.is-active {
        color: $info;
        border-color: $border;
        background: $border;
        + .collapse__body {
          border-color: $border; } } }
    .collapse__body {
      margin-bottom: 1px;
      border: 1px solid $main;
      border-top: 0;
      padding: 2.2rem 1.5rem; } } }

.list {
  margin: 2.5rem 0 1rem;
  &_cols {
    columns: 2;
    li {
      display: inline-block;
      vertical-align: top; }
    &_expandable {
      &.is-active {
        li {
          &:nth-child(n+11) {
            display: inline-block; } } } } }
  &_expandable {
    li {
      &:nth-child(n+11) {
        display: none; } }
    &.is-active {
      li {
        &:nth-child(n+11) {
          display: block; } } } }
  li {
    position: relative;
    margin-bottom: 1.3rem;
    padding-left: 3rem;
    &:before {
      content: '';
      position: absolute;
      left: 5px;
      top: 50%;
      margin-top: -.5rem;
      width: 1.3rem;
      height: 1.1rem;
      background: url(../img/check.svg) 0 0 no-repeat; } } }

.guesthouse {
  margin: 0 -1rem -1px;
  border-top: 1px solid $border;
  border-bottom: 1px solid $border;
  padding: 0 1rem 3.5rem;
  &_item {
    padding-bottom: 0;
    border-bottom: 0; }
  .form {
    margin-top: 3.5rem; } }

.table-wrap {
  margin-bottom: 2.5rem;
  padding: 0 2rem; }

.table {
  width: 100%;
  border: 1px solid $main;
  thead {
    th {
      padding: 1rem 1.5rem;
      background: $main;
      color: #fff;
      text-align: left;
      font-weight: 300;
      font-size: 1.5rem; }
    .icon {
      fill: #fff;
      &-arrow-r {
        font-size: 1.2rem; }
      &-house {
        font-size: 1.6rem; } } }
  tbody {
    font-size: 1.4rem;
    .icon {
      fill: $text;
      font-size: 1.3rem; } }
  td {
    box-shadow: inset 0 1px #ddd;
    padding: .9rem .7rem; }
  tr {
    &:first-child {
      td {
        box-shadow: none; } }
    &:nth-child(2n+1) {
      background: #f9f9f9; } }
  &_transfer {
    td {
      &:first-child {
        width: 3.3rem; }
      &:last-child {
        padding-right: 1.3rem;
        text-align: right;
        color: $danger;
        white-space: nowrap; } } }
  &_simple {
    border: 0;
    tr {
      &:nth-child(2n) {
        background: #eee; }
      &:nth-child(2n+1) {
        background: #f6f6f6; } }
    td {
      padding: .8rem 1.2rem;
      box-shadow: inset 0 1px #fff; } } }

.table {
  + .map {
    margin-top: 1.7rem; } }

.promo {
  margin: 0 -1rem;
  padding: 2.6rem 1rem;
  font-size: 1.5rem;
  color: $info;
  line-height: 1.53;
  background: $panel;
  span {
    text-decoration: line-through; }
  strong {
    color: $danger; } }

.badge-wrap {
  margin: 1.5rem 0; }

.badge {
  display: inline-block;
  position: relative;
  padding: .7rem 1.3rem;
  @include round;
  background: #bcf5d4;
  color: $info;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.1;
  &_info {
    background: $second; }
  &_fluid {
    text-align: center;
    width: 100%; }
  &_t {
    padding-left: 2rem;
    padding-right: 2rem;
    &:before {
      content: '';
      position: absolute;
      top: -6px;
      left: 50%;
      margin-left: -3px;
      @include arr(7, 6, #bcf5d4, t); } } }


.booking {
  margin: 1rem -1rem 0;
  padding: .8rem 1rem .6rem;
  background: $panel;
  color: #545454;
  &__title {
    margin-bottom: .5rem;
    font-size: 1.6rem;
    text-align: center; }
  &__info {
    display: flex;
    justify-content: center;
    font-size: 1.3rem;
    line-height: 1.5; }
  &__list {
    .icon {
      vertical-align: middle; } } }

.checkbox-group {
  margin-bottom: 2.2rem;
  &__title {
    margin-bottom: 1.8rem;
    text-align: center;
    color: #505050;
    font-size: 1.4rem;
    font-weight: 700; }
  .title {
    margin-bottom: 1.5rem; } }

.checkbox {
  display: block;
  margin-bottom: .8rem;
  input {
    display: none;
    &:checked {
      + .checkbox__text {
          &:after {
            display: block; } } } }
  &__text {
    position: relative;
    padding-left: 2.8rem;
    display: block;
    color: #515151;
    font-size: 1.5rem;
    font-weight: 300;
    @include nosel;
    &:before {
      content: '';
      position: absolute;
      left: .5rem;
      top: .3rem;
      width: 1.3rem;
      height: 1.3rem;
      border: 1px solid #d6d6d6;
      background-color: #fff; }
    &:after {
      display: none;
      content: '';
      position: absolute;
      left: .9rem;
      top: .7rem;
      width: .5rem;
      height: .5rem;
      background: $main; } }
  &__icon {
    display: inline-block;
    margin-left: -1rem;
    width: 4rem;
    text-align: center;
    .icon {
      fill: $main;
      vertical-align: middle;
      &-wifi {
        font-size: 1.6rem; }
      &-jacuzzi {
        font-size: 1.9rem; }
      &-air {
        font-size: 1.3rem; }
      &-elevator {
        font-size: 1.9rem; }
      &-washing {
        font-size: 1.9rem; }
      &-tv {
        font-size: 1.8rem; }
      &-microwave {
        font-size: 1.5rem; }
      &-fridge {
        font-size: 1.9rem; } } } }


.radio-group {
  padding: 1.3rem 0;
  .radio {
    margin-bottom: 2rem; } }

.radio {
  display: block;
  margin-bottom: .8rem;
  input {
    display: none;
    &:checked {
      + .radio__text {
        &:after {
          display: block; } } } }
  &__text {
    position: relative;
    padding-left: 3rem;
    display: block;
    color: #515151;
    font-size: 1.5rem;
    font-weight: 300;
    strong {
      display: block;
      margin-bottom: .7rem; }
    &:before {
      content: '';
      position: absolute;
      left: .5rem;
      top: .1rem;
      width: 1.7rem;
      height: 1.7rem;
      border: 1px solid #c3c3c3;
      background: #fff;
      border-radius: 50%; }
    &:after {
      content: '';
      display: none;
      position: absolute;
      left: 1rem;
      top: .6rem;
      width: .7rem;
      height: .7rem;
      background: $text;
      border-radius: 50%; } } }


.small-text {
  margin: 1.5rem 0 0;
  font-size: 1.2rem;
  font-weight: 300;
  color: $info;
  text-align: center;
  a {
    color: $main; }
  &_info {
    font-size: 1.3rem; }
  &_mb {
    margin-bottom: 1.5rem; } }

.required-message {
  display: block;
  margin-bottom: 1.5rem;
  color: #f00;
  font-size: 1.4rem;
  font-weight: 300;
  text-align: center;
  &_small {
    font-size: 1.3rem; } }

.error {
  padding: 3rem 0 5rem;
  text-align: center;
  &__type {
    color: #df1820;
    font-size: 7.5rem;
    font-weight: 700;
    line-height: 1.1; }
  &__text {
    margin-bottom: 2.2rem;
    font-size: 1.5rem;
    color: #df1820; }
  &__help {
    margin: 0 auto;
    max-width: 30rem; } }

.contact {
  padding: 1rem 0 2rem;
  text-align: center;
  font-size: 1.6rem;
  color: $info;
  a {
    color: $main; } }

.form-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -.8rem;
  padding: .5rem 0;
  &__cell {
    width: 50%;
    padding: 0 .8rem; } }

.btn-link {
  margin-bottom: .6rem;
  background: none;
  color: $main;
  font-size: 1.6rem;
  font-weight: 300;
  span {
    display: inline-block;
    border-bottom: 1px solid;
    transition: color .3s; }
  .icon {
    margin-right: .5rem;
    vertical-align: middle;
    fill: $main;
    transition: transform .2s; }
  &:hover {
    span {
      border-color: transparent; } }
  &.is-active {
    .icon-caret-r {
      transform: rotate(90deg); } } }

.datepicker-readonly {
  .ui-datepicker-calendar {
    pointer-events: none; } }

.switch-content {
  display: none;
  &.is-active {
    display: block; } }

.tooltipstered {
  @include nosel; }

.p-prldr {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 10;
  .svg-anm {
    position: absolute;
    width: 70px;
    height: 70px;
    background: url(../img/spinning-circles.svg) center center no-repeat;
    background-size: 66px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto; } }

.f-verification {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: all .3s;
  z-index: 10;
  &.show {
    transition: all .3s;
    display: block; }
  &__block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    margin: auto;
    height: 60%;
    border: 15px solid yellow;
    // transform: translate(0, -50%)
    background: #fff; }
  &__btn {
    position: absolute;
    bottom: 15px;
    width: 80%;
    .btn {
      font-size: 15px;
      background: #319FD5;
      text-transform: inherit; } }
  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all .3s;
    z-index: -1;
    &.show-bg {
      background: rgba(0, 0, 0, 0.3);
      transition: transform .3s; } } }
