html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; vertical-align: baseline; }

html { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }

img, fieldset, a img { border: none; }

input[type="text"], input[type="email"], input[type="tel"], textarea { -webkit-appearance: none; }

input[type="submit"], button { cursor: pointer; }

input[type="submit"]::-moz-focus-inner, button::-moz-focus-inner { padding: 0; border: 0; }

textarea { overflow: auto; }

input, button { margin: 0; padding: 0; border: 0; }

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus { outline: none; }

ul, ol { list-style-type: none; }

table { border-spacing: 0; border-collapse: collapse; width: 100%; }

html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

.icon-air { width: 1.85em; height: 1em; fill: initial; }

.icon-arrow-r { width: 1em; height: 1em; fill: initial; }

.icon-bag { width: 1em; height: 1em; fill: initial; }

.icon-building { width: 1em; height: 1em; fill: initial; }

.icon-buildings { width: 0.85em; height: 1em; fill: initial; }

.icon-calendar-full { width: 0.93em; height: 1em; fill: initial; }

.icon-calendar { width: 0.93em; height: 1em; fill: initial; }

.icon-caret-l { width: 0.59em; height: 1em; fill: #fff; }

.icon-caret-main-next { width: 0.57em; height: 1em; fill: #2ecc71; }

.icon-caret-main-prev { width: 0.55em; height: 1em; fill: #2ecc71; }

.icon-caret-r { width: 0.59em; height: 1em; fill: #fff; }

.icon-check { width: 1.18em; height: 1em; fill: #2ecc71; }

.icon-checkbox { width: 1em; height: 1em; fill: initial; }

.icon-clock { width: 1em; height: 1em; fill: initial; }

.icon-download { width: 1.17em; height: 1em; fill: #2ecc71; }

.icon-elevator { width: 1em; height: 1em; fill: initial; }

.icon-email { width: 1.51em; height: 1em; fill: initial; }

.icon-facebook { width: 0.53em; height: 1em; fill: #000; }

.icon-filter { width: 1em; height: 1em; fill: initial; }

.icon-fridge { width: 0.74em; height: 1em; fill: initial; }

.icon-hash { width: 1em; height: 1em; fill: #444; }

.icon-house { width: 1em; height: 1em; fill: initial; }

.icon-in { width: 1em; height: 1em; fill: #2ecc71; }

.icon-jacuzzi { width: 1em; height: 1em; fill: initial; }

.icon-microwave { width: 1.47em; height: 1em; fill: initial; }

.icon-out { width: 1em; height: 1em; fill: #2ecc71; }

.icon-pin { width: 0.7em; height: 1em; fill: initial; }

.icon-plane { width: 1em; height: 1em; fill: initial; }

.icon-print { width: 0.91em; height: 1em; fill: #2ecc71; }

.icon-road { width: 1em; height: 1em; fill: initial; }

.icon-skype-full { width: 1em; height: 1em; fill: #2ecc71; }

.icon-skype { width: 1em; height: 1em; fill: #2ecc71; }

.icon-tel-full { width: 1em; height: 1em; fill: #2ecc71; }

.icon-tel { width: 1em; height: 1em; fill: initial; }

.icon-tv { width: 1.06em; height: 1em; fill: initial; }

.icon-user { width: 1em; height: 1em; fill: initial; }

.icon-washing { width: 0.84em; height: 1em; fill: initial; }

.icon-wifi { width: 1em; height: 1em; fill: initial; }

html { font-size: 62.5%; }

body { color: #454343; font-family: 'Roboto', -apple-system-body, sans-serif; font-size: 1.5rem; font-weight: 300; line-height: 1.33; min-width: 320px; }

a { text-decoration: none; color: #2ecc71; }

.hidden { display: none; }

.inner { padding: 4.9rem 1rem 0; }

.inner > .filter { margin: 0 -1rem; }

.page-head .title { margin: 1.7rem auto; }

.text-center { text-align: center !important; }

.text-right { text-align: right !important; }

.text-left { text-align: left !important; }

.title { max-width: 92%; margin: 2.2rem auto 1.9rem; text-align: center; color: #565656; font-size: 1.8rem; font-weight: 700; }

.title_small { margin: 2.2rem 0 1.9rem; font-size: 1.6rem; }

.title_tiny { font-size: 1.5rem; }

.title_left { max-width: inherit; text-align: left; }

.title_line { position: relative; padding-bottom: 2.5rem; }

.title_line:after { content: ''; position: absolute; bottom: 0; left: 50%; margin-left: -7rem; width: 14.1rem; height: 1px; background: #e1e1e1; }

.title_light { margin-bottom: 1rem; font-weight: 300; }

.title_main { color: #2ecc71; }

.title_width { max-width: inherit; }

.title_mb { margin-bottom: 0; }

.form-wrap { margin-bottom: 1.5rem; }

.form { padding: 1.5rem 1rem; margin: 0 -1rem; background: #f2f2f2; }

.form__group { margin-bottom: 2.5rem; }

.form__dots { margin-bottom: 1.4rem; border: 1px dashed #c3c3c3; padding: 0 1.7rem; }

.form .title { max-width: inherit; margin: 0 0 1.5rem; }

.form_contact { position: relative; }

.field-row { position: relative; display: flex; margin: 0 -1rem; align-items: flex-end; justify-content: space-between; }

.field-row .field { padding: 0 1rem; flex: auto; }

.field-row .field:first-child .field__datepicker { left: 1rem; }

.field-row .field:first-child .field__datepicker:before { left: 40px; }

.field-row .field:first-child .field__datepicker:after { left: 39px; }

.field-row .field:last-child .field__datepicker { right: 1rem; }

.field-row .field:last-child .field__datepicker:before { right: 40px; }

.field-row .field:last-child .field__datepicker:after { right: 39px; }

.field { display: block; width: 100%; position: relative; margin-bottom: 1.8rem; }

.field.is-active .field__datepicker { opacity: 1; visibility: visible; }

.field .input_datepicker { font-size: 1.5rem; }

.field .input_datepicker[disabled] { background: #fff; -webkit-text-fill-color: #85857e !important; opacity: 1 !important; }

.field__in { position: relative; }

.field__in.is-active .input { box-shadow: inset 0 0 0 1px #2ecc71; }

.field__datepicker { position: absolute; top: 100%; z-index: 50; margin: 1rem 0 0; opacity: 0; visibility: hidden; transition: opacity .3s; }

.field__datepicker:before, .field__datepicker:after { content: ''; position: absolute; }

.field__datepicker:before { z-index: 10; top: -7px; width: 0px; height: 0px; border-style: solid; border-width: 0 7px 8px 7px; border-color: transparent transparent #fff transparent; }

.field__datepicker:after { z-index: 9; top: -8px; width: 0px; height: 0px; border-style: solid; border-width: 0 8px 9px 8px; border-color: transparent transparent #e1e1e1 transparent; }

.field__datepicker.is-active { opacity: 1; visibility: visible; }

.field__title { display: block; margin-bottom: .5rem; font-size: 1.5rem; font-weight: 300; color: #565656; }

.field .icon { position: absolute; z-index: 2; pointer-events: none; left: 1.4rem; top: 50%; transform: translateY(-50%); fill: #bab89f; }

.field .icon-email { font-size: 1.2rem; }

.field span.is-invalid { position: absolute; left: 0; top: -1.4rem; color: #e7371b; font-size: 1rem; }

.field_no-icon .input { padding: 0 1.3rem; }

.field_no-icon .input_textarea { padding: 1.3rem; }

.input { display: block; padding: 0 1rem 0 4rem; width: 100%; height: 4.1rem; box-shadow: inset 0 0 0.4rem 0.1rem rgba(58, 57, 34, 0.25); border-radius: 3px; font-family: 'Roboto', -apple-system-body, sans-serif; font-weight: 300; color: #85857e; background: #fff; -webkit-appearance: none; font-size: 1.6rem; text-align: left; }

.input::placeholder { color: #85857e; font-size: 1.5rem; }

.input_textarea { border: 0; resize: none; height: 13rem; padding: 1.3rem; }

.input_promo { padding: 0 1.2rem; height: 2.8rem; font-size: 1.3rem; background-color: #fffcd2; }

.input_promo::placeholder { font-size: 1.3rem; }

.input:focus { box-shadow: inset 0 0 0 1px #e1e1e1; }

.input:focus ~ .icon { fill: #2ecc71; }

.input.is-invalid { border: 1px solid #e7371b; }

span.input { line-height: 4.1rem; }

.select { display: block; position: relative; }

.select:after { content: ''; position: absolute; right: 1.8rem; top: 50%; margin-top: -3px; width: 0px; height: 0px; border-style: solid; border-width: 7px 4px 0 4px; border-color: #565656 transparent transparent transparent; }

.select select { position: absolute; left: 0; top: 0; width: 100%; height: 100%; opacity: 0; -webkit-appearance: none; }

.select .input { padding-right: 4rem; height: 4.1rem; line-height: 4.1rem; }

.select .input.is-empty { color: #85857e; font-size: 1.5rem; }

.btn-wrap { margin: 2.5rem 0; }

.btn-group { margin: 3rem -1rem; border-bottom: 1px solid #e1e1e1; padding: 0 1rem 2.2rem; }

.btn-group .btn { margin-bottom: 1.2rem; }

.btn { display: block; position: relative; padding: 0 .5rem; width: 100%; height: 4.1rem; line-height: 4.1rem; border-radius: 3px; background: #2ecc71; color: #fff; font-family: 'Roboto', -apple-system-body, sans-serif; font-size: 1.8rem; font-weight: 700; text-align: center; text-transform: uppercase; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

.btn:active { background: #29b765; }

.btn__icon { position: absolute; left: .9rem; top: 50%; line-height: 1; transform: translateY(-50%); }

.btn__icon .icon-print { font-size: 2.3rem; }

.btn__icon .icon-download { font-size: 1.8rem; }

.btn_normal { text-transform: none; }

.btn_light { font-size: 1.5rem; font-weight: 300; }

.btn_plain { border: 1px solid #2ecc71; background: none; color: #2ecc71; }

.btn_mb { margin-bottom: 1rem; }

.btn_tiny { font-size: 1.2rem; text-transform: none; font-weight: 300; height: 2.8rem; line-height: 2.6rem; }

.logo { padding: .8rem 0 0; font: 0/0 a; text-shadow: none; color: transparent; text-align: center; }

.logo-text { max-width: 27rem; margin: 0 auto; padding: .7rem 0; color: #3b3b3b; font-size: 1.2rem; font-weight: 300; text-align: center; }

.btn-more-wrap { padding: 0 2.5rem; }

.btn-more-wrap_mb { margin-bottom: 3.5rem; }

.btn-more-wrap_mt { margin-top: 2rem; }

.btn-more { display: block; padding: 0 .5rem; width: 100%; height: 4.2rem; line-height: 4rem; border-radius: 3px; border: 1px dashed #2ecc71; color: #2ecc71; font-family: 'Roboto', -apple-system-body, sans-serif; font-size: 1.5rem; font-weight: 300; text-transform: uppercase; background: none; text-align: center; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

.btn-more_small { height: 3.2rem; line-height: 3rem; font-size: 1.4rem; }

.btn-more_no-border { border: 0; }

.section { margin: 3.2rem -1rem 0; padding: .5rem 1rem 3.5rem; border-top: 1px solid #e1e1e1; border-bottom: 1px solid #e1e1e1; }

.pseudolink { display: inline-block; border-bottom: 1px dashed; text-decoration: none; color: #2ecc71; background: none; font-family: 'Roboto', -apple-system-body, sans-serif; font-size: 1.6rem; }

.pseudolink_small { font-size: 1.4rem; }

.pseudolink_big { font-size: 1.8rem; }

.readmore { margin-bottom: 3.3rem; }

.readmore_single { margin-bottom: 0; }

.readmore.is-active .readmore__short { display: none; }

.readmore.is-active .readmore__full { display: block; }

.readmore__short { margin-bottom: 1rem; height: 6rem; }

.readmore__full { margin-bottom: 1rem; display: none; }

.rate-group { margin-bottom: .7rem; display: flex; align-items: center; justify-content: space-between; color: #44d07d; font-size: 1.3rem; font-weight: 300; }

.rate-group__left, .rate-group__right { display: flex; justify-content: center; }

.rate-group__left { width: 40%; }

.rate-group__right { width: 60%; }

.rate-wrap { text-align: center; }

.rate-wrap .rate { margin-bottom: .5rem; }

.hidden-map { text-align: center; }

.hidden-map .map { margin: 1.5rem 0; }

.hidden-map .map__iframe { padding: 0; opacity: 0; transition: padding .3s, opacity .3s .3s; }

.hidden-map .map.is-active .map__iframe { padding: 40% 0; opacity: 1; }

.map-wrap { margin: 0 -1rem; padding: 0 1rem; border-top: 1px solid #e1e1e1; }

.map { position: relative; margin: 0 -1rem; }

.map__body { padding: 63% 0; }

.map__iframe { padding: 40% 0; }

.map__iframe iframe { position: absolute; left: 0; top: 0; width: 100%; height: 100%; }

.map_marked { padding: 1rem 1rem 1.5rem; background: #fff88d; }

.map_marked .title { margin-bottom: 3.1rem; }

.map_small .map__body { padding: 27% 0; }

.img { margin-bottom: 2rem; }

.img figcaption { padding: 2.2rem .7rem; font-size: 1.8rem; color: #454343; line-height: 1.6; }

.img figcaption strong { display: block; }

.img img { display: block; width: 100%; }

.collapse { margin-bottom: 3.3rem; }

.collapse__head { margin: 0 -1rem 1px; padding: 1.5rem 2.1rem; color: #fff; line-height: 1.1; font-size: 1.8rem; font-weight: 700; background: #2ecc71; }

.collapse__body { padding: 2.2rem 0; display: none; }

.collapse__body .article p:first-child { margin-top: 0; }

.collapse__body .article p:last-child { margin-bottom: 0; }

.collapse_inner .collapse__head { margin: 1px 0 0; border: 1px solid #2ecc71; padding: 1.5rem; background: none; color: #2ecc71; font-size: 1.5rem; font-weight: 300; }

.collapse_inner .collapse__head.is-active { color: #565656; border-color: #e1e1e1; background: #e1e1e1; }

.collapse_inner .collapse__head.is-active + .collapse__body { border-color: #e1e1e1; }

.collapse_inner .collapse__body { margin-bottom: 1px; border: 1px solid #2ecc71; border-top: 0; padding: 2.2rem 1.5rem; }

.list { margin: 2.5rem 0 1rem; }

.list_cols { columns: 2; }

.list_cols li { display: inline-block; vertical-align: top; }

.list_cols_expandable.is-active li:nth-child(n+11) { display: inline-block; }

.list_expandable li:nth-child(n+11) { display: none; }

.list_expandable.is-active li:nth-child(n+11) { display: block; }

.list li { position: relative; margin-bottom: 1.3rem; padding-left: 3rem; }

.list li:before { content: ''; position: absolute; left: 5px; top: 50%; margin-top: -.5rem; width: 1.3rem; height: 1.1rem; background: url(../img/check.svg) 0 0 no-repeat; }

.guesthouse { margin: 0 -1rem -1px; border-top: 1px solid #e1e1e1; border-bottom: 1px solid #e1e1e1; padding: 0 1rem 3.5rem; }

.guesthouse_item { padding-bottom: 0; border-bottom: 0; }

.guesthouse .form { margin-top: 3.5rem; }

.table-wrap { margin-bottom: 2.5rem; padding: 0 2rem; }

.table { width: 100%; border: 1px solid #2ecc71; }

.table thead th { padding: 1rem 1.5rem; background: #2ecc71; color: #fff; text-align: left; font-weight: 300; font-size: 1.5rem; }

.table thead .icon { fill: #fff; }

.table thead .icon-arrow-r { font-size: 1.2rem; }

.table thead .icon-house { font-size: 1.6rem; }

.table tbody { font-size: 1.4rem; }

.table tbody .icon { fill: #454343; font-size: 1.3rem; }

.table td { box-shadow: inset 0 1px #ddd; padding: .9rem .7rem; }

.table tr:first-child td { box-shadow: none; }

.table tr:nth-child(2n+1) { background: #f9f9f9; }

.table_transfer td:first-child { width: 3.3rem; }

.table_transfer td:last-child { padding-right: 1.3rem; text-align: right; color: #e7371b; white-space: nowrap; }

.table_simple { border: 0; }

.table_simple tr:nth-child(2n) { background: #eee; }

.table_simple tr:nth-child(2n+1) { background: #f6f6f6; }

.table_simple td { padding: .8rem 1.2rem; box-shadow: inset 0 1px #fff; }

.table + .map { margin-top: 1.7rem; }

.promo { margin: 0 -1rem; padding: 2.6rem 1rem; font-size: 1.5rem; color: #565656; line-height: 1.53; background: #f2f2f2; }

.promo span { text-decoration: line-through; }

.promo strong { color: #e7371b; }

.badge-wrap { margin: 1.5rem 0; }

.badge { display: inline-block; position: relative; padding: .7rem 1.3rem; border-radius: 3px; background: #bcf5d4; color: #565656; font-size: 1.4rem; font-weight: 300; line-height: 1.1; }

.badge_info { background: #fff88d; }

.badge_fluid { text-align: center; width: 100%; }

.badge_t { padding-left: 2rem; padding-right: 2rem; }

.badge_t:before { content: ''; position: absolute; top: -6px; left: 50%; margin-left: -3px; width: 0px; height: 0px; border-style: solid; border-width: 0 3.5px 6px 3.5px; border-color: transparent transparent #bcf5d4 transparent; }

.booking { margin: 1rem -1rem 0; padding: .8rem 1rem .6rem; background: #f2f2f2; color: #545454; }

.booking__title { margin-bottom: .5rem; font-size: 1.6rem; text-align: center; }

.booking__info { display: flex; justify-content: center; font-size: 1.3rem; line-height: 1.5; }

.booking__list .icon { vertical-align: middle; }

.checkbox-group { margin-bottom: 2.2rem; }

.checkbox-group__title { margin-bottom: 1.8rem; text-align: center; color: #505050; font-size: 1.4rem; font-weight: 700; }

.checkbox-group .title { margin-bottom: 1.5rem; }

.checkbox { display: block; margin-bottom: .8rem; }

.checkbox input { display: none; }

.checkbox input:checked + .checkbox__text:after { display: block; }

.checkbox__text { position: relative; padding-left: 2.8rem; display: block; color: #515151; font-size: 1.5rem; font-weight: 300; -webkit-touch-callout: none; -ms-text-size-adjust: none; -webkit-text-size-adjust: none; -webkit-tap-highlight-color: transparent; user-select: none; }

.checkbox__text:before { content: ''; position: absolute; left: .5rem; top: .3rem; width: 1.3rem; height: 1.3rem; border: 1px solid #d6d6d6; background-color: #fff; }

.checkbox__text:after { display: none; content: ''; position: absolute; left: .9rem; top: .7rem; width: .5rem; height: .5rem; background: #2ecc71; }

.checkbox__icon { display: inline-block; margin-left: -1rem; width: 4rem; text-align: center; }

.checkbox__icon .icon { fill: #2ecc71; vertical-align: middle; }

.checkbox__icon .icon-wifi { font-size: 1.6rem; }

.checkbox__icon .icon-jacuzzi { font-size: 1.9rem; }

.checkbox__icon .icon-air { font-size: 1.3rem; }

.checkbox__icon .icon-elevator { font-size: 1.9rem; }

.checkbox__icon .icon-washing { font-size: 1.9rem; }

.checkbox__icon .icon-tv { font-size: 1.8rem; }

.checkbox__icon .icon-microwave { font-size: 1.5rem; }

.checkbox__icon .icon-fridge { font-size: 1.9rem; }

.radio-group { padding: 1.3rem 0; }

.radio-group .radio { margin-bottom: 2rem; }

.radio { display: block; margin-bottom: .8rem; }

.radio input { display: none; }

.radio input:checked + .radio__text:after { display: block; }

.radio__text { position: relative; padding-left: 3rem; display: block; color: #515151; font-size: 1.5rem; font-weight: 300; }

.radio__text strong { display: block; margin-bottom: .7rem; }

.radio__text:before { content: ''; position: absolute; left: .5rem; top: .1rem; width: 1.7rem; height: 1.7rem; border: 1px solid #c3c3c3; background: #fff; border-radius: 50%; }

.radio__text:after { content: ''; display: none; position: absolute; left: 1rem; top: .6rem; width: .7rem; height: .7rem; background: #454343; border-radius: 50%; }

.small-text { margin: 1.5rem 0 0; font-size: 1.2rem; font-weight: 300; color: #565656; text-align: center; }

.small-text a { color: #2ecc71; }

.small-text_info { font-size: 1.3rem; }

.small-text_mb { margin-bottom: 1.5rem; }

.required-message { display: block; margin-bottom: 1.5rem; color: #f00; font-size: 1.4rem; font-weight: 300; text-align: center; }

.required-message_small { font-size: 1.3rem; }

.error { padding: 3rem 0 5rem; text-align: center; }

.error__type { color: #df1820; font-size: 7.5rem; font-weight: 700; line-height: 1.1; }

.error__text { margin-bottom: 2.2rem; font-size: 1.5rem; color: #df1820; }

.error__help { margin: 0 auto; max-width: 30rem; }

.contact { padding: 1rem 0 2rem; text-align: center; font-size: 1.6rem; color: #565656; }

.contact a { color: #2ecc71; }

.form-action { display: flex; align-items: center; justify-content: space-between; margin: 0 -.8rem; padding: .5rem 0; }

.form-action__cell { width: 50%; padding: 0 .8rem; }

.btn-link { margin-bottom: .6rem; background: none; color: #2ecc71; font-size: 1.6rem; font-weight: 300; }

.btn-link span { display: inline-block; border-bottom: 1px solid; transition: color .3s; }

.btn-link .icon { margin-right: .5rem; vertical-align: middle; fill: #2ecc71; transition: transform .2s; }

.btn-link:hover span { border-color: transparent; }

.btn-link.is-active .icon-caret-r { transform: rotate(90deg); }

.datepicker-readonly .ui-datepicker-calendar { pointer-events: none; }

.switch-content { display: none; }

.switch-content.is-active { display: block; }

.tooltipstered { -webkit-touch-callout: none; -ms-text-size-adjust: none; -webkit-text-size-adjust: none; -webkit-tap-highlight-color: transparent; user-select: none; }

.p-prldr { position: absolute; left: 0; top: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.85); z-index: 10; }

.p-prldr .svg-anm { position: absolute; width: 70px; height: 70px; background: url(../img/spinning-circles.svg) center center no-repeat; background-size: 66px; top: 0; left: 0; bottom: 0; right: 0; margin: auto; }

.f-verification { display: none; position: fixed; left: 0; right: 0; top: 0; bottom: 0; margin: auto; transition: all .3s; z-index: 10; }

.f-verification.show { transition: all .3s; display: block; }

.f-verification__block { display: flex; justify-content: center; align-items: center; width: 95%; position: absolute; top: 70px; left: 0; right: 0; margin: auto; height: 60%; border: 15px solid yellow; background: #fff; }

.f-verification__btn { position: absolute; bottom: 15px; width: 80%; }

.f-verification__btn .btn { font-size: 15px; background: #319FD5; text-transform: inherit; }

.f-verification__overlay { position: absolute; top: 0; right: 0; bottom: 0; left: 0; transition: all .3s; z-index: -1; }

.f-verification__overlay.show-bg { background: rgba(0, 0, 0, 0.3); transition: transform .3s; }

.header { position: fixed; top: 0; left: 0; right: 0; z-index: 52; display: flex; align-items: stretch; padding: 0 1rem; background-image: linear-gradient(to top, #faee38 0%, #faee38 1%, #fff891 100%); }

.header__right, .header__left { display: flex; width: 50%; align-items: center; }

.header__right { justify-content: flex-end; }

.header .drop { margin-left: 1rem; }

.burger { margin-left: -1rem; border-right: 1px solid #fff; width: 6.2rem; height: 4.9rem; line-height: 1; font-size: 0; background: #2ecc71; }

.burger.is-active i { background: none; }

.burger.is-active i:before { transform: translateY(1rem) rotate(45deg); }

.burger.is-active i:after { transform: translateY(-1rem) rotate(-45deg); }

.burger i { display: inline-block; position: relative; vertical-align: middle; height: 3px; width: 3.6rem; background: #fff; }

.burger i:before, .burger i:after { content: ""; position: absolute; left: 0; height: 3px; background: #fff; width: 3.6rem; transition: transform .3s; }

.burger i:before { top: -1rem; }

.burger i:after { bottom: -1rem; }

.burger_main { position: absolute; right: 0; top: 4.9rem; height: 4.1rem; width: 4.9rem; background: none; }

.burger_main i { background: #2ecc71; width: 2.4rem; }

.burger_main i:before, .burger_main i:after { background: #2ecc71; width: 2.4rem; }

.burger_main i:before { top: -.7rem; }

.burger_main i:after { bottom: -.7rem; }

.burger_main.is-active i { background: none; }

.burger_main.is-active i:before { transform: translateY(0.7rem) rotate(45deg); }

.burger_main.is-active i:after { transform: translateY(-0.7rem) rotate(-45deg); }

.drop { position: relative; font-size: 1.4rem; font-weight: 300; color: #787100; }

.drop_currency { font-size: 1.1rem; text-transform: uppercase; }

.drop img { vertical-align: middle; margin-right: .7rem; }

.drop__lang { font-size: 1.4rem; }

.drop__value { display: block; position: relative; z-index: 2; border: 1px solid #d5cc3f; padding: 0 2rem 0 .8rem; height: 3.4rem; line-height: 3.2rem; border-radius: 3px; background: #fff88d; -webkit-touch-callout: none; -ms-text-size-adjust: none; -webkit-text-size-adjust: none; -webkit-tap-highlight-color: transparent; user-select: none; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

.drop__value:after { content: ''; position: absolute; top: 50%; margin-top: -3px; right: .7rem; width: 0px; height: 0px; border-style: solid; border-width: 5px 3.5px 0 3.5px; border-color: #d5cc3f transparent transparent transparent; }

.drop__value.is-active { border-bottom-color: transparent; border-radius: 3px 3px 0 0; }

.drop__list { opacity: 0; visibility: hidden; position: absolute; top: 100%; left: 0; margin-top: -.8rem; border: 1px solid #d5cc3f; border-top: 0; padding: 4px 0; transform: translateY(-1rem); min-width: 100%; border-radius: 0 0 3px 3px; background: #fff88d; transition: opacity, transform, .3s; }

.drop__list.is-active { opacity: 1; visibility: visible; transform: none; }

.drop__list a { display: block; padding: .5rem 2rem .5rem .8rem; color: #787100; text-decoration: none; }

.menu-bar { position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 51; transition: transform .3s; transform: translate3d(-100%, 0, 0); }

.menu-bar.is-active { transform: translate3d(0, 0, 0); }

.menu-bar.is-active .menu__item.is-active .submenu-wrap { transform: translate3d(0, 0, 0); }

.menu-bar.is-active .menu-bar__overlay { background: rgba(0, 0, 0, 0.3); transition: background .2s .3s; }

.menu-bar__overlay { position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: -1; }

.menu { padding-top: 4.9rem; background: #2ecc71; max-height: 100%; overflow-y: auto; overflow-x: hidden; }

.menu > li.is-current { background: #5ff09d; }

.menu > li > a { display: block; border-top: 1px solid #45dd86; padding: 1.7rem 2.5rem; color: #fff; font-family: 'Roboto', -apple-system-body, sans-serif; line-height: 1; font-size: 1.5rem; font-weight: 300; text-decoration: none; text-transform: uppercase; }

.submenu-wrap { position: fixed; left: 0; right: 0; top: 0; max-height: 100%; background: #fff; overflow-y: auto; overflow-x: hidden; transition: transform .3s; line-height: 1; transform: translate3d(-100%, 0, 0); }

.submenu { padding-top: 9rem; }

.submenu__item { border-top: 1px solid #e1e1e1; }

.submenu__link { display: block; padding: 1.5rem 1.3rem; color: #2ecc71; font-size: 1.5rem; font-weight: 700; text-decoration: none; }

.submenu__list-item { border-top: 1px solid #e1e1e1; }

.submenu__list-link { display: block; padding: 1.5rem 3.5rem; color: #545454; font-size: 1.5rem; text-decoration: none; font-weight: 300; }

.submenu__list-link strong { font-weight: 700; }

.filter { height: 7.2rem; }

.filter__head { display: flex; align-items: center; justify-content: space-between; padding: 0 1rem; background: #f2f2f2; height: 7.2rem; font-size: 1.6rem; }

.filter__date { font-size: 1.6rem; }

.filter__date-selected { display: none; }

.filter__date.is-active .filter__date-default { display: none; }

.filter__date.is-active .filter__date-selected { display: block; }

.filter__btn { position: relative; background: none; }

.filter__btn .icon { fill: #2ecc71; font-size: 2.5rem; }

.filter__btn .window__arr { display: none; }

.filter__btn.is-active .window__arr { display: block; right: 50%; transform: translate(50%, 0); bottom: -21px; top: auto; }

.filter__counter { position: absolute; bottom: .3rem; right: 1.4rem; padding: .1rem .4rem; min-width: 1.6rem; min-height: 1.6rem; text-align: center; line-height: 1; font-size: 1.4rem; font-weight: 300; color: #fff; background: #dc1f26; border-radius: 50%; transition: opacity .3s; }

.filter__counter:empty { opacity: 0; }

.window { position: fixed; top: 12rem; left: 0; right: 0; bottom: 0; z-index: 50; transform: translate3d(-100%, 0, 0); transition: transform .3s; }

.window.is-active { transform: translate3d(0, 0, 0); }

.window.is-active .window__overlay { background: rgba(0, 0, 0, 0.3); transition: background .2s .3s; }

.window_dates { z-index: 50; }

.window_dates .window__in { min-height: 45rem; }

.window_dates .window__arr { right: auto; left: 1.7rem; }

.window__overlay { position: absolute; top: 0; right: 0; bottom: 0; left: 0; }

.window__in { position: absolute; top: 0; right: 0; max-height: 100%; left: 0; z-index: 20; padding: 1rem; background: #fff; }

.window__in .field-row { margin-top: 20px; }

.window .checkbox { margin-bottom: 1.1rem; }

.window__arr { position: absolute; right: 1.7rem; top: -1.3rem; width: 0px; height: 0px; border-style: solid; border-width: 0 6px 14px 6px; border-color: transparent transparent #fff transparent; }

.window .burger { top: 0; z-index: 25; }

.window .form-action { position: fixed; bottom: 0; left: 0; right: 0; padding: 1rem; background: #fff; margin: 0; }

.message { margin: 1.5rem 0 2rem; border: 4px solid; padding: 2rem 2rem; background: #fffcd4; font-size: 1.6rem; font-weight: 300; color: #3b3b3b; text-align: center; }

.message_error { border-color: #ff4b4b; }

.message_error .message__main { margin-top: 1.7rem; color: #df1820; }

.message a { color: #3dcf7b; }

.fix-filter-top { top: 49px; transition: all 0.5s; }

@media only screen and (min-width: 600px) { .window .form-action { position: absolute; }
  .window__in { padding-bottom: 61px; } }

.sort { position: fixed; bottom: 0; left: 0; right: 0; transform: translate3d(0, 100%, 0); transition: transform .5s; background: #f2f2f2; margin: 0; z-index: 10; }

.sort-show { transform: translate3d(0, 0, 0); }

.sort__block { display: flex; align-items: center; justify-content: space-between; border-top: 1px dashed #2ecc71; }

.sort__cell { position: relative; width: 50%; padding: 1.5rem .8rem; }

.sort__cell:first-of-type:before { content: ''; position: absolute; width: 1px; height: 35px; background: #e0e0e0; right: 0; }

.sort__cell-active:after { content: ''; position: absolute; top: 0; right: 48%; border-width: 16px 13px 0 13px; border-style: solid; border-color: #fff #eef3f9; }

.sort__item { position: absolute; bottom: 72px; width: 100%; background: #fff; }

.sort__checkbox { vertical-align: top; width: 17px; height: 17px; margin: 0 3px 0 0; }

.sort__checkbox + label { cursor: pointer; display: block; font-size: 18px; color: #565656; }

.sort__checkbox:not(checked) { position: absolute; opacity: 0; }

.sort__checkbox:not(checked) + label { position: relative; padding: 16px 0 16px 50px; }

.sort__checkbox:not(checked) + label:before { content: ''; position: absolute; top: 4px; left: 10px; width: 25px; height: 25px; }

.sort__checkbox:not(checked) + label:after { content: ''; position: absolute; background-image: url("../img/sort-select.png"); top: 17px; left: 10px; width: 25px; height: 18px; opacity: 0; transition: all .2s; }

.sort__checkbox:checked + label:after { opacity: 1; }

.btn_sort { color: #2ecc71; background: inherit; font-weight: normal; }

.btn_sort:active { background: inherit; }

.assort-item { transform: translate3d(-100%, 0, 0); transition: transform .5s; border-top: 1px dashed #2ecc71; }

.assort-item-show { transform: translate3d(0, 0, 0); }

.window-close { position: absolute; width: 40px; height: 40px; top: 10px; right: 10px; cursor: pointer; }

.window-close:before, .window-close:after { content: ''; position: absolute; top: 0; left: 0; bottom: 0; right: 0; margin: auto; height: 3px; background: #2ecc71; width: 2.6rem; }

.window-close:before { transform: rotate(45deg); }

.window-close:after { transform: rotate(-45deg); }

.slider { visibility: hidden; height: 0; overflow: hidden; }

.slider.is-ready { visibility: visible; height: auto; }

.slider img { margin: 0 auto; height: 215px !important; width: auto; }

.slick-slider { position: relative; z-index: 1; }

.slick-slide { background: rgba(0, 0, 0, 0.5); text-align: center; overflow: hidden; }

.guesthouse .slick-slide { background: transparent; }

.slick-slide img { width: auto; }

.slick-arrow { position: absolute; top: 0; z-index: 20; height: 100%; width: 5rem; padding: 0 1rem; font: 0/0 a; text-shadow: none; color: transparent; background: none; text-align: right; }

.slick-arrow .icon { fill: #fff; font-size: 2.7rem; }

.slick-prev { left: 0; }

.slick-next { right: 0; }

.item-wrap { border-bottom: 1px solid #e1e1e1; }

.item { display: block; margin: 0 -1rem 1rem; -webkit-touch-callout: none; -ms-text-size-adjust: none; -webkit-text-size-adjust: none; -webkit-tap-highlight-color: transparent; user-select: none; }

.item__link { display: block; }

.item__media { position: relative; min-height: 215px; }

.item__media img { width: auto; }

.item__badge { position: absolute; top: .5rem; z-index: 2; padding: .5rem .8rem .4rem; color: #fff; font-size: 1.4rem; font-weight: 300; line-height: 1; background: rgba(30, 30, 30, 0.58); }

.item__badge_id { right: 0; text-transform: capitalize; }

.item__badge_date { left: 0; }

.item__details { display: block; position: relative; padding: 1rem; font-size: 1.4rem; color: #565656; }

.item__row { display: flex; margin: 0 0 .8rem; align-items: center; justify-content: space-between; }

.item__row:first-child { margin-bottom: .2rem; }

.item__row-equals { margin: 0 -1rem; }

.item__row-equals .item__left, .item__row-equals .item__right { padding: 0 1rem; width: 50%; }

.item__row-equals .field { margin-bottom: 0; }

.item__row-mb { margin-bottom: 1.1rem; }

.item__row-mb:first-child { margin-bottom: 1.1rem; }

.item__left, .item__right { display: flex; align-items: center; }

.item__left { justify-content: flex-start; }

.item__left .field__datepicker { left: 0; margin-left: 0; }

.item__right { justify-content: flex-end; text-align: right; }

.item__right .field__datepicker { left: auto; right: 0; margin-left: 0; }

.item .price-old { margin-right: .5rem; }

.item_info { margin-top: 2rem; border-top: 1px solid #e1e1e1; border-bottom: 1px solid #e1e1e1; }

.item_card { background: #f2f2f2; }

.item_card .price-old { color: #aeaeae; }

.item_card .item__details { padding-bottom: 1.5rem; }

.item__details .item__row:nth-last-child(1) .item__left { padding-right: 15px; }

.item__details .item__row:nth-last-child(1) .item__right { min-width: 75px; }

.price-old { color: #a5a5a5; font-weight: 300; font-size: 1.6rem; }

.price-old span { text-decoration: line-through; }

.price-old_small { font-size: 1.3rem; }

.price { display: flex; align-items: center; }

.price__main { color: #2ecc71; font-size: 2.2rem; font-weight: 700; }

.price__details { padding-left: .7rem; color: #656565; font-size: 1rem; font-weight: 300; line-height: 0.9; }

.price__details span { display: block; }

.price__details_main { color: #2ecc71; text-decoration: underline; }

.price_light .price__main { font-weight: 300; font-size: 3.3rem; }

.price_light .price__details { line-height: 1.1; color: #565656; }

.link { color: #2ecc71; font-weight: 300; font-size: 1.5rem; text-decoration: none; }

.link_small { font-size: 1.2rem; }

.rate { display: inline-block; position: relative; padding: .5rem .8rem; border-radius: 3px; background: #fff88d; color: #787100; font-weight: 300; font-size: 1.2rem; }

.rate:before { content: ''; position: absolute; top: -6px; right: 14px; width: 0px; height: 0px; border-style: solid; border-width: 0 3.5px 6px 3.5px; border-color: transparent transparent #fff88d transparent; }

.rate_b:before { top: auto; bottom: -6px; right: 50%; margin-right: -3px; width: 0px; height: 0px; border-style: solid; border-width: 6px 3.5px 0 3.5px; border-color: #fff88d transparent transparent transparent; }

.rate_r:before { top: 50%; right: -6px; margin-top: -3px; width: 0px; height: 0px; border-style: solid; border-width: 3px 0 3px 7px; border-color: transparent transparent transparent #fff88d; }

.address { color: #565656; font-size: 1.5rem; font-weight: 700; }

.address_card { margin: .5rem 0 0; font-weight: 300; font-size: 2rem; }

.address_card strong { font-weight: 700; }

.address_card sup { color: #878787; font-size: 1.2rem; vertical-align: super; }

.meta { color: #adab8f; font-size: 1.4rem; font-weight: 300; line-height: 1; }

.meta .icon { vertical-align: middle; font-size: 1.7rem; fill: #adab8f; }

.meta span { vertical-align: middle; }

@keyframes spin { 0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); } }

.loader { position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 5; display: none; align-items: center; justify-content: center; background: rgba(0, 0, 0, 0.5); }

.loader.is-active { display: flex; }

.loader.is-active i { animation: spin 1s infinite linear; }

.loader i { display: block; border-radius: 50%; width: 4rem; height: 4rem; border: 0.5rem solid rgba(255, 255, 255, 0.2); border-top-color: #fff; }

.dashed-line { margin: 1rem 0 0; border-top: 1px dashed #c7c7c7; height: 0; }

.card { border-bottom: 1px dashed #e1e1e1; padding: 1.7rem 0; color: #565656; font-size: 1.6rem; }

.card figure { padding-top: 2.5rem; text-align: center; }

.card figure img { max-width: 100%; height: auto; }

.card figcaption { padding: 1.5rem 0; }

.card .title:first-child { margin-top: 0; }

.card_no-border { border: 0; }

.icons-row { display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap; }

.icon-wrap { position: relative; }

.icon-wrap.is-disabled .icon { fill: #bababa; }

.icon-wrap .icon { fill: #2ecc71; font-size: 1.9rem; }

.icon-wrap .icon-wifi { font-size: 1.6rem; }

.icon-wrap .icon-air { font-size: 1.3rem; }

.icon-wrap .icon-tv { font-size: 1.8rem; }

.icon-wrap .icon-microwave { font-size: 1.5rem; }

.tooltipster-base { z-index: 2 !important; padding: .5rem .8rem; height: auto !important; color: #565656; font-size: 1.3rem; line-height: 1.2; font-weight: 300; white-space: nowrap; filter: drop-shadow(0 0 3px rgba(127, 127, 127, 0.65)); background: #fff; }

.tooltipster-base:before { content: ''; position: absolute; top: -4px; left: 50%; margin-left: -3px; width: 0px; height: 0px; border-style: solid; border-width: 0 3px 5px 3px; border-color: transparent transparent #fff transparent; }

.table-row-wrap .pseudolink { margin-bottom: 3rem; }

.table-row { position: relative; display: flex; justify-content: space-between; margin: 0 -.5rem 1.6rem; font-size: 1.5rem; }

.table-row:after { content: ''; position: absolute; bottom: 4px; left: 0; right: 0; border: 1px dotted #565656; }

.table-row__cell { position: relative; padding: 0 .5rem; z-index: 2; background: #fff; }

.table-row__cell a { font-weight: 700; text-decoration: underline; }

.table-row.is-hidden { display: none; }

.table-row.is-hidden.is-active { display: flex; }

.article { color: #454343; font-size: 1.5rem; font-weight: 300; line-height: 1.33; margin: 2.2rem 0; }

.article p { margin: 2.2rem 0; }

.article h1, .article h2 { margin: 35px 0 32px; color: #565656; font-size: 1.8rem; font-weight: 700; text-align: center; }

.article figure { margin: 0 -1rem; }

.article img { display: block; width: 100%; height: auto; }

.article a { color: #2ecc71; }

.article ul { margin: 3.2rem 0; }

.article ul li { position: relative; margin-bottom: 1.3rem; padding-left: 3rem; }

.article ul li:before { content: ''; position: absolute; left: 5px; top: 50%; margin-top: -.5rem; width: 1.3rem; height: 1.1rem; background: url(../img/check.svg) 0 0 no-repeat; }

.article_mb { margin-bottom: 2.5rem; }

.article_dots-list ul { list-style-type: disc; padding-left: 1em; }

.article_dots-list ul li { padding-left: 0; }

.article_dots-list ul li:before { display: none; }

.reviews-total { display: flex; align-items: center; padding: .5rem 0 3rem; }

.reviews-total__link { margin-left: 1rem; display: inline-block; }

.reviews-list { margin: 0 -1rem 2.8rem; padding: 0 0 2.4rem; background: #f2f2f2; }

.review { background: #f2f2f2; padding: 1.6rem 1rem 1.3rem 2.3rem; border-bottom: 1px solid #fff; }

.review:nth-last-child(2) { border: 0; }

.review__row { display: flex; margin-bottom: 1rem; align-items: center; justify-content: space-between; }

.review__left { text-align: center; min-width: 4.8rem; width: 4.8rem; }

.review__right { flex: auto; padding-left: 1.5rem; text-align: left; }

.review__user { color: #565656; font-size: 2rem; font-weight: 300; }

.review__status { color: #e7371b; font-size: 2.8rem; font-weight: 700; }

.review__positive { color: #2ecc71; }

.review__negative { color: #e7371b; }

.review__text { color: #565656; font-size: 1.5rem; font-weight: 300; line-height: 1.33; }

.mark { position: relative; display: flex; align-items: center; justify-content: center; width: 4.8rem; height: 4.3rem; border-radius: 3px 3px 3px 0; background-color: #1877a7; }

.mark__in { line-height: 1.1; color: #fff; font-weight: 300; font-size: 1.1rem; text-align: center; }

.mark:before { content: ''; position: absolute; left: 0; bottom: -7px; width: 0px; height: 0px; border-style: solid; border-width: 8px 7px 0 0; border-color: #1877a7 transparent transparent transparent; }

.mark span { font-size: 1.6rem; display: block; }

.mark-total { position: relative; display: flex; align-items: center; padding: 1rem; border-radius: 5px 5px 5px 0; background: #1877a7; color: #fff; }

.mark-total:before { content: ''; position: absolute; left: 0; bottom: -7px; width: 0px; height: 0px; border-style: solid; border-width: 8px 7px 0 0; border-color: #1877a7 transparent transparent transparent; }

.mark-total__in { display: flex; align-items: center; border-top: 1px solid #328fbe; border-bottom: 1px solid #328fbe; width: 100%; }

.mark-total__value { color: #fff; font-size: 4.3rem; font-weight: 300; }

.mark-total__details { text-transform: uppercase; font-size: 1.2rem; }

.mark-total__details span { display: block; color: #fff; font-size: 1rem; font-weight: 300; }

.footer { border-top: 2px solid #fff; padding: .5rem 1rem 3rem 3.5rem; background: #f2f2f2; color: #565656; font-size: 1.4rem; }

.list-icons { margin-bottom: 2.8rem; color: #565656; font-size: 1.4rem; }

.list-icons li { margin-bottom: .5rem; }

.list-icons a { display: inline-block; color: #565656; text-decoration: none; }

.list-icons__icon { display: inline-block; width: 2rem; }

.list-icons .icon { fill: #2ecc71; font-size: 1.2rem; }

.list-icons__text strong { display: block; }

.list-icons_main { font-size: 1.5rem; }

.list-icons_main a { color: #2ecc71; }

.list-icons_main .list-icons__icon { width: 2.2rem; }

.list-icons_main .icon { font-size: 1.5rem; }

.list-icons_main .icon-email { font-size: 1.2rem; }

.list-icons_info { columns: 2; }

.list-icons_info li { display: inline-block; width: 100%; vertical-align: top; }

.list-icons_info .list-icons__in { display: flex; align-items: center; }

.list-icons_info .list-icons__icon { width: 2.8rem; }

.list-icons_info .icon { font-size: 1.5rem; fill: #000; vertical-align: middle; }

.list-icons_info .icon-user { font-size: 1.7rem; }

.list-icons_info .icon-buildings { font-size: 1.9rem; }

.list-links { font-size: 1.4rem; font-weight: 300; }

.list-links a { display: inline-block; padding: 1px 0; color: #2ecc71; text-decoration: underline; }

.fb-counter { margin-bottom: 1rem; }

.popup { position: fixed; left: 0; right: 0; top: 0; bottom: 0; display: flex; align-items: center; justify-content: center; opacity: 0; z-index: 10; visibility: hidden; transition: opacity .3s; }

.popup__in { position: relative; z-index: 2; padding: 2rem; width: 26rem; color: #565656; font-size: 1.3rem; line-height: 1; font-weight: 300; box-shadow: 0 0 3px rgba(127, 127, 127, 0.65); background: #fff; text-align: left; }

.popup__in li { margin-bottom: 1rem; }

.popup__in li:last-child { margin-bottom: 0; }

.popup__in .title { margin: 1rem 0 2rem; }

.popup__overlay { position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.3); }

.popup.is-active { opacity: 1; visibility: visible; }

.popup_fluid .popup__in { width: 90%; }

/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; }

.slick-track:before, .slick-track:after { content: ""; display: table; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }

[dir="rtl"] .slick-slide { float: right; }

.slick-slide img { display: block; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

.ui-widget-content { position: relative; z-index: 8 !important; padding-bottom: .5rem; color: #565656; }

.ui-datepicker { width: 300px; margin-top: 11px; display: none; border: 1px solid #e1e1e1; background: #fff; }

.ui-datepicker-inline { display: block; margin: 0 auto; box-shadow: none; border-radius: 0; }

.ui-datepicker-inline tbody td { border-color: #dedede; }

.ui-datepicker .ui-datepicker-header { position: relative; padding: 10px 0; }

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next { position: absolute; top: 50%; margin-top: -2.5rem; width: 4rem; height: 5rem; font: 0/0 a; text-shadow: none; color: transparent; cursor: pointer; }

.ui-datepicker .ui-datepicker-prev { left: 0; background: url(../img/caret-main-prev.svg) 50% 50% no-repeat; }

.ui-datepicker .ui-datepicker-next { right: 0; background: url(../img/caret-main-next.svg) 50% 50% no-repeat; }

.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span { display: block; position: absolute; left: 50%; margin-left: -8px; top: 50%; margin-top: -8px; }

.ui-datepicker .ui-datepicker-title { margin: 0 2.3em; line-height: 1.8em; text-align: center; }

.ui-datepicker-month, .ui-datepicker-year { width: 45%; font-size: 1.8rem; margin: 0; }

.ui-datepicker-month { margin-right: 5%; }

.ui-datepicker table { width: 26rem; border-collapse: collapse; margin: 0 auto; table-layout: fixed; }

.ui-datepicker th { padding: .6rem .5rem; text-align: center; font-size: 1.5rem; color: #565656; font-weight: 300; border: 0; }

.ui-datepicker td { vertical-align: middle; border-top: 1px solid #dedede; }

.ui-datepicker td:first-child { border-left: 0; }

.ui-datepicker td.ui-datepicker-unselectable a, .ui-datepicker td.ui-datepicker-unselectable span { color: #e5e5e5; }

.ui-datepicker td.ui-datepicker-unselectable.ui-datepicker-other-month a, .ui-datepicker td.ui-datepicker-unselectable.ui-datepicker-other-month span { text-decoration: line-through; color: #a3a3a3; }

.ui-datepicker td.ui-datepicker-today a, .ui-datepicker td.ui-datepicker-today span { font-weight: 700; color: #000; }

.ui-datepicker td.ui-datepicker-current-day a, .ui-datepicker td.ui-datepicker-current-day span { background: #2ecc71; color: #fff; }

.ui-datepicker td.ui-datepicker-current-day.ui-datepicker-today a, .ui-datepicker td.ui-datepicker-current-day.ui-datepicker-today span { background: none; font-weight: 700; color: #000; }

.ui-datepicker td.ui-datepicker-range a, .ui-datepicker td.ui-datepicker-range span { background: #a6ffcc; color: #2ecc71; }

.ui-datepicker td.ui-datepicker-busy a, .ui-datepicker td.ui-datepicker-busy span { background: #fe5555; color: #fff; }

.ui-datepicker td.ui-datepicker-range-start a, .ui-datepicker td.ui-datepicker-range-start span, .ui-datepicker td.ui-datepicker-range-end a, .ui-datepicker td.ui-datepicker-range-end span { background: #2ecc71 !important; color: #fff !important; }

.ui-datepicker td span, .ui-datepicker td a { display: block; text-align: center; text-decoration: none; font-size: 1.8rem; color: #565656; width: 100%; height: 40px; line-height: 40px; }

.ui-datepicker .ui-datepicker-buttonpane { background-image: none; margin: .7em 0 0 0; padding: 0 .2em; border-left: 0; border-right: 0; border-bottom: 0; }

.ui-datepicker .ui-datepicker-buttonpane button { float: right; margin: .5em .2em .4em; cursor: pointer; padding: .2em .6em .3em .6em; width: auto; overflow: visible; }

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current { float: left; }

.ui-datepicker.ui-datepicker-multi { width: auto; }

.ui-datepicker-multi .ui-datepicker-group { float: left; }

.ui-datepicker-multi .ui-datepicker-group table { width: 95%; margin: 0 auto .4em; }

.ui-datepicker-multi-2 .ui-datepicker-group { width: 50%; }

.ui-datepicker-multi-3 .ui-datepicker-group { width: 33.3%; }

.ui-datepicker-multi-4 .ui-datepicker-group { width: 25%; }

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header { border-left-width: 0; }

.ui-datepicker-multi .ui-datepicker-buttonpane { clear: left; }

.ui-datepicker-row-break { clear: both; width: 100%; font-size: 0; }

.ps { -ms-touch-action: auto; touch-action: auto; overflow: hidden !important; -ms-overflow-style: none; }

@supports (-ms-overflow-style: none) { .ps { overflow: auto !important; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { .ps { overflow: auto !important; } }

.ps.ps--active-x > .ps__scrollbar-x-rail, .ps.ps--active-y > .ps__scrollbar-y-rail { display: block; background-color: transparent; }

.ps.ps--in-scrolling.ps--x > .ps__scrollbar-x-rail { background-color: #eee; opacity: 0.9; }

.ps.ps--in-scrolling.ps--x > .ps__scrollbar-x-rail > .ps__scrollbar-x { background-color: #999; height: 11px; }

.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail { background-color: #eee; opacity: 0.9; }

.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y { background-color: #999; width: 11px; }

.ps > .ps__scrollbar-x-rail { display: none; position: absolute; /* please don't change 'position' */ opacity: 0; transition: background-color .2s linear, opacity .2s linear; bottom: 0px; /* there must be 'bottom' for ps__scrollbar-x-rail */ height: 15px; }

.ps > .ps__scrollbar-x-rail > .ps__scrollbar-x { position: absolute; /* please don't change 'position' */ background-color: #aaa; border-radius: 6px; transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out; bottom: 2px; /* there must be 'bottom' for ps__scrollbar-x */ height: 6px; }

.ps > .ps__scrollbar-x-rail:hover > .ps__scrollbar-x, .ps > .ps__scrollbar-x-rail:active > .ps__scrollbar-x { height: 11px; }

.ps > .ps__scrollbar-y-rail { display: none; position: absolute; /* please don't change 'position' */ opacity: 0; transition: background-color .2s linear, opacity .2s linear; right: 0; /* there must be 'right' for ps__scrollbar-y-rail */ width: 15px; }

.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y { position: absolute; /* please don't change 'position' */ background-color: #aaa; border-radius: 6px; transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out; right: 2px; /* there must be 'right' for ps__scrollbar-y */ width: 6px; }

.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y, .ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y { width: 11px; }

.ps:hover.ps--in-scrolling.ps--x > .ps__scrollbar-x-rail { background-color: #eee; opacity: 0.9; }

.ps:hover.ps--in-scrolling.ps--x > .ps__scrollbar-x-rail > .ps__scrollbar-x { background-color: #999; height: 11px; }

.ps:hover.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail { background-color: #eee; opacity: 0.9; }

.ps:hover.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y { background-color: #999; width: 11px; }

.ps:hover > .ps__scrollbar-x-rail, .ps:hover > .ps__scrollbar-y-rail { opacity: 0.6; }

.ps:hover > .ps__scrollbar-x-rail:hover { background-color: #eee; opacity: 0.9; }

.ps:hover > .ps__scrollbar-x-rail:hover > .ps__scrollbar-x { background-color: #999; }

.ps:hover > .ps__scrollbar-y-rail:hover { background-color: #eee; opacity: 0.9; }

.ps:hover > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y { background-color: #999; }

/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap, .fancybox-skin, .fancybox-outer, .fancybox-inner, .fancybox-image, .fancybox-wrap iframe, .fancybox-wrap object, .fancybox-nav, .fancybox-nav span, .fancybox-tmp { padding: 0; margin: 0; border: 0; outline: none; vertical-align: top; }

.fancybox-wrap { position: absolute; top: 0; left: 0; z-index: 8020; }

.fancybox-skin { position: relative; background: #f9f9f9; color: #444; text-shadow: none; -webkit-border-radius: 4px; -moz-border-radius: 4px; border-radius: 4px; }

.fancybox-opened { z-index: 8030; }

.fancybox-opened .fancybox-skin { -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); }

.fancybox-outer, .fancybox-inner { position: relative; }

.fancybox-inner { overflow: hidden; }

.fancybox-type-iframe .fancybox-inner { -webkit-overflow-scrolling: touch; }

.fancybox-error { color: #444; font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif; margin: 0; padding: 15px; white-space: nowrap; }

.fancybox-image, .fancybox-iframe { display: block; width: 100%; height: 100%; }

.fancybox-image { max-width: 100%; max-height: 100%; }

#fancybox-loading { position: fixed; top: 50%; left: 50%; margin-top: -22px; margin-left: -22px; background-position: 0 -108px; opacity: 0.8; cursor: pointer; z-index: 8060; }

#fancybox-loading div { width: 44px; height: 44px; }

.fancybox-close { position: absolute; top: -18px; right: -18px; width: 36px; height: 36px; cursor: pointer; z-index: 8040; }

.fancybox-nav { position: absolute; top: 0; width: 40%; height: 100%; cursor: pointer; text-decoration: none; -webkit-tap-highlight-color: transparent; z-index: 8040; }

.fancybox-prev { left: 0; }

.fancybox-next { right: 0; }

.fancybox-nav span { position: absolute; top: 50%; width: 36px; height: 34px; margin-top: -18px; cursor: pointer; z-index: 8040; visibility: hidden; }

.fancybox-prev span { left: 10px; background-position: 0 -36px; }

.fancybox-next span { right: 10px; background-position: 0 -72px; }

.fancybox-nav:hover span { visibility: visible; }

.fancybox-tmp { position: absolute; top: -99999px; left: -99999px; max-width: 99999px; max-height: 99999px; overflow: visible !important; }

/* Overlay helper */
.fancybox-lock { overflow: visible !important; width: auto; }

.fancybox-lock body { overflow: hidden !important; }

.fancybox-lock-test { overflow-y: hidden !important; }

.fancybox-overlay { position: absolute; top: 0; left: 0; overflow: hidden; display: none; z-index: 8010; }

.fancybox-overlay-fixed { position: fixed; bottom: 0; right: 0; }

.fancybox-lock .fancybox-overlay { overflow: auto; overflow-y: scroll; }

/* Title helper */
.fancybox-title { visibility: hidden; position: relative; text-shadow: none; z-index: 8050; }

.fancybox-opened .fancybox-title { visibility: visible; }

.fancybox-title-float-wrap { position: absolute; bottom: 0; right: 50%; margin-bottom: -35px; z-index: 8050; text-align: center; }

.fancybox-title-float-wrap .child { display: inline-block; margin-right: -100%; padding: 2px 20px; background: transparent; /* Fallback for web browsers that doesn't support RGBa */ background: rgba(0, 0, 0, 0.8); -webkit-border-radius: 15px; -moz-border-radius: 15px; border-radius: 15px; text-shadow: 0 1px 2px #222; color: #FFF; font-weight: bold; line-height: 24px; white-space: nowrap; }

.fancybox-title-outside-wrap { position: relative; margin-top: 10px; color: #fff; }

.fancybox-title-inside-wrap { padding-top: 10px; }

.fancybox-title-over-wrap { position: absolute; bottom: 0; left: 0; color: #fff; padding: 10px; background: #000; background: rgba(0, 0, 0, 0.8); }

/*Retina graphics!*/
/* This is the core CSS of Tooltipster */
/* GENERAL STRUCTURE RULES (do not edit this section) */
.tooltipster-base { /* this ensures that a constrained height set by functionPosition, if greater that the natural height of the tooltip, will be enforced in browsers that support display:flex */ display: flex; pointer-events: none; /* this may be overriden in JS for fixed position origins */ position: absolute; }

.tooltipster-box { /* see .tooltipster-base. flex-shrink 1 is only necessary for IE10- and flex-basis auto for IE11- (at least) */ flex: 1 1 auto; }

.tooltipster-content { /* prevents an overflow if the user adds padding to the div */ box-sizing: border-box; /* these make sure we'll be able to detect any overflow */ max-height: 100%; max-width: 100%; overflow: auto; }

.tooltipster-ruler { /* these let us test the size of the tooltip without overflowing the window */ bottom: 0; left: 0; overflow: hidden; position: fixed; right: 0; top: 0; visibility: hidden; }

/* ANIMATIONS */
/* Open/close animations */
/* fade */
.tooltipster-fade { opacity: 0; -webkit-transition-property: opacity; -moz-transition-property: opacity; -o-transition-property: opacity; -ms-transition-property: opacity; transition-property: opacity; }

.tooltipster-fade.tooltipster-show { opacity: 1; }

/* grow */
.tooltipster-grow { -webkit-transform: scale(0, 0); -moz-transform: scale(0, 0); -o-transform: scale(0, 0); -ms-transform: scale(0, 0); transform: scale(0, 0); -webkit-transition-property: -webkit-transform; -moz-transition-property: -moz-transform; -o-transition-property: -o-transform; -ms-transition-property: -ms-transform; transition-property: transform; -webkit-backface-visibility: hidden; }

.tooltipster-grow.tooltipster-show { -webkit-transform: scale(1, 1); -moz-transform: scale(1, 1); -o-transform: scale(1, 1); -ms-transform: scale(1, 1); transform: scale(1, 1); -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15); -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15); -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15); -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15); transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15); }

/* swing */
.tooltipster-swing { opacity: 0; -webkit-transform: rotateZ(4deg); -moz-transform: rotateZ(4deg); -o-transform: rotateZ(4deg); -ms-transform: rotateZ(4deg); transform: rotateZ(4deg); -webkit-transition-property: -webkit-transform, opacity; -moz-transition-property: -moz-transform; -o-transition-property: -o-transform; -ms-transition-property: -ms-transform; transition-property: transform; }

.tooltipster-swing.tooltipster-show { opacity: 1; -webkit-transform: rotateZ(0deg); -moz-transform: rotateZ(0deg); -o-transform: rotateZ(0deg); -ms-transform: rotateZ(0deg); transform: rotateZ(0deg); -webkit-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 1); -webkit-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4); -moz-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4); -ms-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4); -o-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4); transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4); }

/* fall */
.tooltipster-fall { -webkit-transition-property: top; -moz-transition-property: top; -o-transition-property: top; -ms-transition-property: top; transition-property: top; -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15); -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15); -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15); -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15); transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15); }

.tooltipster-fall.tooltipster-initial { top: 0 !important; }

.tooltipster-fall.tooltipster-dying { -webkit-transition-property: all; -moz-transition-property: all; -o-transition-property: all; -ms-transition-property: all; transition-property: all; top: 0 !important; opacity: 0; }

/* slide */
.tooltipster-slide { -webkit-transition-property: left; -moz-transition-property: left; -o-transition-property: left; -ms-transition-property: left; transition-property: left; -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15); -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15); -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15); -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15); transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15); }

.tooltipster-slide.tooltipster-initial { left: -40px !important; }

.tooltipster-slide.tooltipster-dying { -webkit-transition-property: all; -moz-transition-property: all; -o-transition-property: all; -ms-transition-property: all; transition-property: all; left: 0 !important; opacity: 0; }

/* Update animations */
/* We use animations rather than transitions here because transition durations may be specified in the style tag due to animationDuration, and we try to avoid collisions and the use of !important */
/* fade */
@keyframes tooltipster-fading { 0% { opacity: 0; }
  100% { opacity: 1; } }

.tooltipster-update-fade { animation: tooltipster-fading 400ms; }

/* rotate */
@keyframes tooltipster-rotating { 25% { transform: rotate(-2deg); }
  75% { transform: rotate(2deg); }
  100% { transform: rotate(0); } }

.tooltipster-update-rotate { animation: tooltipster-rotating 600ms; }

/* scale */
@keyframes tooltipster-scaling { 50% { transform: scale(1.1); }
  100% { transform: scale(1); } }

.tooltipster-update-scale { animation: tooltipster-scaling 600ms; }

.fancybox-close { position: absolute; top: 0; left: 0; opacity: 0; width: 100%; height: 100%; }
