.menu-bar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 51;
  transition: transform .3s;
  transform: translate3d(-100%, 0, 0);
  &.is-active {
    transform: translate3d(0, 0, 0);
    .menu__item {
      &.is-active {
        .submenu-wrap {
          transform: translate3d(0, 0, 0); } } }
    .menu-bar__overlay {
      background: rgba(#000, .3);
      transition: background .2s .3s; } }
  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1; } }

.menu {
  padding-top: 4.9rem;
  background: $main;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  > li {
    &.is-current {
      background: #5ff09d; }
    > a {
      display: block;
      border-top: 1px solid #45dd86;
      padding: 1.7rem 2.5rem;
      color: #fff;
      @include font;
      line-height: 1;
      font-size: 1.5rem;
      font-weight: 300;
      text-decoration: none;
      text-transform: uppercase; } } }

.submenu-wrap {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  max-height: 100%;
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  transition: transform .3s;
  line-height: 1;
  transform: translate3d(-100%, 0, 0); }

.submenu {
  padding-top: 9rem;
  &__item {
    border-top: 1px solid $border; }
  &__link {
    display: block;
    padding: 1.5rem 1.3rem;
    color: $main;
    font-size: 1.5rem;
    font-weight: 700;
    text-decoration: none; }
  &__list {
    &-item {
      border-top: 1px solid $border; }
    &-link {
      display: block;
      padding: 1.5rem 3.5rem;
      color: #545454;
      font-size: 1.5rem;
      text-decoration: none;
      font-weight: 300;
      strong {
        font-weight: 700; } } } }


