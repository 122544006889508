.reviews-total {
  display: flex;
  align-items: center;
  padding: .5rem 0 3rem;
  &__link {
    margin-left: 1rem;
    display: inline-block; } }

.reviews-list {
  margin: 0 -1rem 2.8rem;
  padding: 0 0 2.4rem;
  background: $panel; }

.review {
  background: $panel;
  padding: 1.6rem 1rem 1.3rem 2.3rem;
  border-bottom: 1px solid #fff;
  &:nth-last-child(2) {
    border: 0; }
  &__row {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: space-between; }
  &__left {
    text-align: center;
    min-width: 4.8rem;
    width: 4.8rem; }
  &__right {
    flex: auto;
    padding-left: 1.5rem;
    text-align: left; }
  &__user {
    color: $info;
    font-size: 2rem;
    font-weight: 300; }
  &__status {
    color: #e7371b;
    font-size: 2.8rem;
    font-weight: 700; }
  &__positive {
    color: $main; }
  &__negative {
    color: #e7371b; }
  &__text {
    color: $info;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.33; } }

.mark {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.3rem;
  border-radius: 3px 3px 3px 0;
  background-color: #1877a7;
  &__in {
    line-height: 1.1;
    color: #fff;
    font-weight: 300;
    font-size: 1.1rem;
    text-align: center; }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -7px;
    @include arr(7,8,#1877a7,tl); }
  span {
    font-size: 1.6rem;
    display: block; } }

.mark-total {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5px 5px 5px 0;
  background: #1877a7;
  color: #fff;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -7px;
    @include arr(7,8,#1877a7,tl); }
  &__in {
    display: flex;
    align-items: center;
    border-top: 1px solid #328fbe;
    border-bottom: 1px solid #328fbe;
    width: 100%; }
  &__value {
    color: #fff;
    font-size: 4.3rem;
    font-weight: 300; }
  &__details {
    text-transform: uppercase;
    font-size: 1.2rem;
    span {
      display: block;
      color: #fff;
      font-size: 1rem;
      font-weight: 300; } } }
