.footer {
  border-top: 2px solid #fff;
  padding: .5rem 1rem 3rem 3.5rem;
  background: $panel;
  color: $info;
  font-size: 1.4rem; }

.list-icons {
  margin-bottom: 2.8rem;
  color: $info;
  font-size: 1.4rem;
  li {
    margin-bottom: .5rem; }
  a {
    display: inline-block;
    color: $info;
    text-decoration: none; }
  &__icon {
    display: inline-block;
    width: 2rem; }
  .icon {
    fill: $main;
    font-size: 1.2rem; }
  &__text {
    strong {
      display: block; } }
  &_main {
    font-size: 1.5rem;
    a {
      color: $main; }
    .list-icons__icon {
      width: 2.2rem; }
    .icon {
      font-size: 1.5rem;
      &-email {
        font-size: 1.2rem; } } } }

.list-icons_info {
  columns: 2;
  li {
    display: inline-block;
    width: 100%;
    vertical-align: top; }
  .list-icons__in {
    display: flex;
    align-items: center; }
  .list-icons__icon {
    width: 2.8rem; }
  .icon {
    font-size: 1.5rem;
    fill: #000;
    vertical-align: middle;
    &-user {
      font-size: 1.7rem; }
    &-buildings {
      font-size: 1.9rem; } } }


.list-links {
  font-size: 1.4rem;
  font-weight: 300;
  a {
    display: inline-block;
    padding: 1px 0;
    color: $main;
    text-decoration: underline; } }

.fb-counter {
  margin-bottom: 1rem; }
