.slider {
  visibility: hidden;
  height: 0;
  overflow: hidden;
  &.is-ready {
    visibility: visible;
    height: auto; }
  img {
    margin: 0 auto;
    height: 215px !important;
    width: auto;
    // object-fit: contain
    // position: absolute
    // right: 0
 } }    // left: 0

.slick {
  &-slider {
    position: relative;
    z-index: 1; }
  &-slide {
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    overflow: hidden;
    .guesthouse & {
        background: rgba(0, 0, 0, 0); }
    // position: relative
    // min-height: 215px!important
    img {
      width: auto; } }
  &-arrow {
    position: absolute;
    top: 0;
    z-index: 20;
    height: 100%;
    width: 5rem;
    padding: 0 1rem;
    @include hide-text;
    background: none;
    text-align: right;
    .icon {
      fill: #fff;
      font-size: 2.7rem; } }
  &-prev {
    left: 0; }
  &-next {
    right: 0; } }

.item-wrap {
  border-bottom: 1px solid $border; }

.item {
  display: block;
  margin: 0 -1rem 1rem;
  @include nosel;
  &__link {
    display: block; }
  &__media {
    position: relative;
    min-height: 215px;
    img {
      width: auto; } }
  &__badge {
    position: absolute;
    top: .5rem;
    z-index: 2;
    padding: .5rem .8rem .4rem;
    color: #fff;
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 1;
    background: rgba(#1e1e1e, .58);
    &_id {
      right: 0;
      text-transform: capitalize; }
    &_date {
      left: 0; } }
  &__details {
    display: block;
    position: relative;
    padding: 1rem;
    font-size: 1.4rem;
    color: $info; }
  &__row {
    display: flex;
    margin: 0 0 .8rem;
    align-items: center;
    justify-content: space-between;
    &:first-child {
      margin-bottom: .2rem; } }
  &__row-equals {
    margin: 0 -1rem;
    .item__left,
    .item__right {
      padding: 0 1rem;
      width: 50%; }
    .field {
      margin-bottom: 0; } }
  &__row-mb {
    margin-bottom: 1.1rem;
    &:first-child {
      margin-bottom: 1.1rem; } }
  &__left,
  &__right {
    display: flex;
    align-items: center; }
  &__left {
    justify-content: flex-start;
    .field {
      &__datepicker {
        left: 0;
        margin-left: 0; } } }
  &__right {
    justify-content: flex-end;
    text-align: right;
    .field {
      &__datepicker {
        left: auto;
        right: 0;
        margin-left: 0; } } }
  .price-old {
    margin-right: .5rem; }
  &_info {
    margin-top: 2rem;
    border-top: 1px solid $border;
    border-bottom: 1px solid $border; }
  &_card {
    background: $panel;
    .price-old {
      color: #aeaeae; }
    .item__details {
      padding-bottom: 1.5rem; } }
  &__details {
    .item__row:nth-last-child(1) {
      .item__left {
        padding-right: 15px; }
      .item__right {
        min-width: 75px; } } } }

.price-old {
  color: #a5a5a5;
  font-weight: 300;
  font-size: 1.6rem;
  span {
    text-decoration: line-through; }
  &_small {
    font-size: 1.3rem; } }

.price {
  display: flex;
  align-items: center;
  &__main {
    color: $main;
    font-size: 2.2rem;
    font-weight: 700; }
  &__details {
    padding-left: .7rem;
    color: #656565;
    font-size: 1rem;
    font-weight: 300;
    line-height: 0.9;
    span {
      display: block; }
    &_main {
      color: $main;
      text-decoration: underline; } }
  &_light {
    .price__main {
      font-weight: 300;
      font-size: 3.3rem; }
    .price__details {
      line-height: 1.1;
      color: $info; } } }

.link {
  color: $main;
  font-weight: 300;
  font-size: 1.5rem;
  text-decoration: none;
  &_small {
    font-size: 1.2rem; } }

.rate {
  display: inline-block;
  position: relative;
  padding: .5rem .8rem;
  @include round;
  background: $second;
  color: #787100;
  font-weight: 300;
  font-size: 1.2rem;
  &:before {
    content: '';
    position: absolute;
    top: -6px;
    right: 14px;
    @include arr(7, 6, $second, t); }
  &_b {
    &:before {
      top: auto;
      bottom: -6px;
      right: 50%;
      margin-right: -3px;
      @include arr(7, 6, $second, b); } }
  &_r {
    &:before {
      top: 50%;
      right: -6px;
      margin-top: -3px;
      @include arr(7, 6, $second, r); } } }

.address {
  color: $info;
  font-size: 1.5rem;
  font-weight: 700;
  &_card {
    margin: .5rem 0 0;
    font-weight: 300;
    font-size: 2rem;
    strong {
      font-weight: 700; }
    sup {
      color: #878787;
      font-size: 1.2rem;
      vertical-align: super; } } }

.meta {
  color: $meta;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1;
  .icon {
    vertical-align: middle;
    font-size: 1.7rem;
    fill: $meta; }
  span {
    vertical-align: middle; } }


@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  display: none;
  align-items: center;
  justify-content: center;
  background: rgba(#000, .5);
  &.is-active {
    display: flex;
    i {
      animation: spin 1s infinite linear; } }
  i {
    display: block;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    border: .5rem solid rgba(#fff, 0.2);
    border-top-color: #fff; } }
