.icon-air {
  width: 1.85em;
  height: 1em;
  fill: initial;
}
.icon-arrow-r {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-bag {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-building {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-buildings {
  width: 0.85em;
  height: 1em;
  fill: initial;
}
.icon-calendar-full {
  width: 0.93em;
  height: 1em;
  fill: initial;
}
.icon-calendar {
  width: 0.93em;
  height: 1em;
  fill: initial;
}
.icon-caret-l {
  width: 0.59em;
  height: 1em;
  fill: #fff;
}
.icon-caret-main-next {
  width: 0.57em;
  height: 1em;
  fill: #2ecc71;
}
.icon-caret-main-prev {
  width: 0.55em;
  height: 1em;
  fill: #2ecc71;
}
.icon-caret-r {
  width: 0.59em;
  height: 1em;
  fill: #fff;
}
.icon-check {
  width: 1.18em;
  height: 1em;
  fill: #2ecc71;
}
.icon-checkbox {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-clock {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-download {
  width: 1.17em;
  height: 1em;
  fill: #2ecc71;
}
.icon-elevator {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-email {
  width: 1.51em;
  height: 1em;
  fill: initial;
}
.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: #000;
}
.icon-filter {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-fridge {
  width: 0.74em;
  height: 1em;
  fill: initial;
}
.icon-hash {
  width: 1em;
  height: 1em;
  fill: #444;
}
.icon-house {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-in {
  width: 1em;
  height: 1em;
  fill: #2ecc71;
}
.icon-jacuzzi {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-microwave {
  width: 1.47em;
  height: 1em;
  fill: initial;
}
.icon-out {
  width: 1em;
  height: 1em;
  fill: #2ecc71;
}
.icon-pin {
  width: 0.7em;
  height: 1em;
  fill: initial;
}
.icon-plane {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-print {
  width: 0.91em;
  height: 1em;
  fill: #2ecc71;
}
.icon-road {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-skype-full {
  width: 1em;
  height: 1em;
  fill: #2ecc71;
}
.icon-skype {
  width: 1em;
  height: 1em;
  fill: #2ecc71;
}
.icon-tel-full {
  width: 1em;
  height: 1em;
  fill: #2ecc71;
}
.icon-tel {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-tv {
  width: 1.06em;
  height: 1em;
  fill: initial;
}
.icon-user {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-washing {
  width: 0.84em;
  height: 1em;
  fill: initial;
}
.icon-wifi {
  width: 1em;
  height: 1em;
  fill: initial;
}
