.article {
  color: $text;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.33;
  margin: 2.2rem 0;
  p {
    margin: 2.2rem 0; }
  h1, h2 {
    margin: 35px 0 32px;
    color: $info;
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center; }
  figure {
    margin: 0 -1rem; }
  img {
    display: block;
    width: 100%;
    height: auto; }
  a {
    color: $main; }
  ul {
    margin: 3.2rem 0;
    li {
      position: relative;
      margin-bottom: 1.3rem;
      padding-left: 3rem;
      &:before {
        content: '';
        position: absolute;
        left: 5px;
        top: 50%;
        margin-top: -.5rem;
        width: 1.3rem;
        height: 1.1rem;
        background: url(../img/check.svg) 0 0 no-repeat; } } }
  &_mb {
    margin-bottom: 2.5rem; }
  &_dots-list {
    ul {
      list-style-type: disc;
      padding-left: 1em;
      li {
        padding-left: 0;
        &:before {
          display: none; } } } } }
